import React, {useState} from 'react'
import {useLocation} from 'react-router-dom';
import {Fab, Action} from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddClient from '../Invoice/AddClient';
import {useTranslation, Trans} from 'react-i18next';

const FabButton = () => {

    const location = useLocation()
    const mainButtonStyles = {backgroundColor: '#1976D2'}
    const [open, setOpen] = useState(false)
    const {t, i18n} = useTranslation();

    // if(location.pathname === '/invoice') return null

    return (
        <div  >
            <AddClient setOpen={setOpen} open={open}/>
            <Fab
                mainButtonStyles={mainButtonStyles}
                icon={<AddIcon/>}
                alwaysShowTitle={true}
                style={{bottom:'60px'}}


            >

                {location.pathname !== '/add/invoice' && (
                    <Action
                        text={t("New_Invoice")}
                        onClick={() => window.location.href = '/add/invoice'}
                    >
                        <CreateIcon/>
                    </Action>
                )}

                <Action
                    text={t("New_Customer")}
                    onClick={() => setOpen((prev) => !prev)}
                >
                    <PersonAddIcon/>
                </Action>

            </Fab>
        </div>
    )
}

export default FabButton
