import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container, Grid,
    Paper,
    Tab,
    TableCell,
    Tabs,
    TextField,
    Typography, withStyles
} from '@material-ui/core';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import {useDispatch, useSelector} from "react-redux";
import {getClientsByUser} from "../../actions/clientActions";
import {getLastInvoiceNumberByUser} from "../../actions/invoiceActions";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import AddClient from "../Invoice/AddClient";
import {CalcPriceWithTax, CalcTax, GenerateSaudiTaxCode, toCommas, toFixedFloat} from "../../utils/utils";
import {paymentMethods} from '../../actions/constants'
import PrintIcon from "@material-ui/icons/Print";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {findItemByBarcode} from "../../actions/itemActions";
import {fetchFile} from "../../actions/upload";
import ProgressButton from "react-progress-button";
import styles from "../InvoiceDetails/InvoiceDetails.module.css";
import axios from "axios";
import {useSnackbar} from 'react-simple-snackbar'
import {saveAs} from 'file-saver';

const useStyles2 = makeStyles(theme => ({
    table: {
        minWidth: 500,
    },
    button: {
        margin: theme.spacing(1),
    },
    tablecell: {
        fontSize: '16px'
    }
}));


const PaymentPage = (props) => {
    const classes = useStyles2();
    //const [client, setClient] = useState(null)
    const client = props.client
    const user = JSON.parse(localStorage.getItem('profile'))
    const clients = useSelector((state) => state.clients.clients)
    const location = useLocation()
    const dispatch = useDispatch()
    const {t, i18n} = useTranslation();

    const [sendStatus, setSendStatus] = useState(null)
    const [downloadStatus, setDownloadStatus] = useState(null)
    // eslint-disable-next-line
    const [openSnackbar, closeSnackbar] = useSnackbar()

    const {profiles} = useSelector((state) => state.profiles)
    const [discount, setDiscount] = useState(0);
    const [discountPercentage, setDiscountPercentage] = useState(0);
    const [totalPayment, setTotalPayment] = useState(0);
    const paymentData = props.paymentData;
    const total = props.card.reduce((a, b) => toFixedFloat(toFixedFloat(a) + toFixedFloat(CalcPriceWithTax(b, profiles?.vatPercentage))), 0);
    const totalDiscount = toFixedFloat(toFixedFloat(discount) + toFixedFloat(total * discountPercentage / 100));
    const totalAfterDiscount = toFixedFloat(total - totalDiscount);
    const totalTax = toFixedFloat(props.card.reduce((a, b) => toFixedFloat(toFixedFloat(a) + toFixedFloat(CalcTax(b, profiles?.vatPercentage))), 0)
        - toFixedFloat(totalDiscount * toFixedFloat(profiles?.vatPercentage / 100)));
    const balance = toFixedFloat(totalAfterDiscount - totalPayment);
    const CustomPaper = (props) => {
        return <Paper elevation={3} {...props} />;
    };
    const clientsProps = {
        options: clients,
        getOptionLabel: (option) => option.name
    };
    const handelPayment = (method, val, fullPayment = false) => {
        const newSate = paymentMethods.map(m => {
            m.title.includes(method) ? m.paidAmount = val : m.paidAmount = (fullPayment ? 0 : m.paidAmount);
            return m;
        });
        props.setPaymentData(newSate)
        setTotalPayment(paymentData.reduce((a, b) => {
            console.log(b.paidAmount)
            return toFixedFloat(toFixedFloat(a) + toFixedFloat(b.paidAmount))
        }, 0));
        console.log(paymentData)
    }

    useEffect(async () => {
        await dispatch(getClientsByUser({search: user?.result._id || user?.result?.googleId}));
        const lastInvoiceNumberData = await dispatch(getLastInvoiceNumberByUser({search: user?.result._id || user?.result?.googleId}));
    }, [dispatch, location]);

    useEffect(async () => {
        (profiles?.currency === "SAR" && profiles?.businessName) ? props.setSaudiQrCode(
                GenerateSaudiTaxCode(profiles.businessName, profiles.vatRecord, new Date().toLocaleString(), totalAfterDiscount, totalTax))
            : props.setSaudiQrCode("null");
    }, [profiles])

    useEffect(async () => {
        props.SetPaymentInfo({
            total: total,
            totalDiscount: totalDiscount,
            totalAfterDiscount: totalAfterDiscount,
            totalTax: totalTax,
            totalPayment: totalPayment,
            balance: balance
        })
    }, [total, totalDiscount, totalAfterDiscount, totalTax, totalPayment, balance])


    const iconSize = {height: '18px', width: '18px', marginRight: '10px', color: 'gray'}

    return (
        <div id="paymentPage" className='np' style={{background: '#ffffff', padding: '20px'}}>
            <form className={classes.root} noValidate autoComplete="off">
                <AddClient setOpen={props.setOpen} open={props.open}/>
                <Container>
                    <Typography variant="overline" style={{color: 'gray', paddingRight: '3px'}}
                                gutterBottom>{t('Bill to')}: </Typography>


                    {client && (
                        <>
                            {client.name} - {client.email} - {client.phone} - {client.address}
                            <Button color="primary" size="small" style={{textTransform: 'none'}}
                                    onClick={() => props.setClient(null)}>{t('Change')}</Button>
                        </>
                    )}
                    <div style={client ? {display: 'none'} : {display: 'block'}}>
                        <Autocomplete
                            {...clientsProps}
                            PaperComponent={CustomPaper}
                            renderInput={(params) => <TextField {...params}
                                                                required={false}
                                                                label={t('Select_Customer')}
                                                                margin="normal"
                                                                variant="outlined"
                            />}
                            value={clients?.name}
                            onChange={(event, value) => props.setClient(value)}

                        />

                    </div>
                    {!client &&
                        <>
                            <Grid item style={{paddingBottom: '10px'}}>
                                <Chip
                                    avatar={<Avatar>+</Avatar>}
                                    label={t('New_Customer')}
                                    onClick={() => props.setOpen(true)}
                                    variant="outlined"
                                />
                            </Grid>
                        </>
                    }
                </Container>

                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Total')}</TableCell>
                                <TableCell>{t('Discount')}</TableCell>
                                <TableCell>{t('Discount%')}</TableCell>
                                <TableCell>{t('Total Discount')}</TableCell>
                                <TableCell>{t('Tax')}</TableCell>
                                <TableCell>{t('Total Due')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow key='t1'>
                                <TableCell>{total}</TableCell>
                                <TableCell scope="row">
                                    {discount}
                                </TableCell>
                                <TableCell scope="row">
                                    {discountPercentage}
                                </TableCell>
                                <TableCell>{totalDiscount}</TableCell>
                                <TableCell>{totalTax}</TableCell>
                                <TableCell>{totalAfterDiscount}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">

                        <TableBody>
                            <TableRow>
                                {paymentData.map((method) => (
                                    <TableCell key={method.note} align="center">
                                        <TextField label={t(method.title)} type="text"
                                                   variant="outlined"
                                                   value={method.paidAmount}
                                                   onFocus={e => e.target.select()}
                                                   onChange={(e, value) => {
                                                       handelPayment(method.title, e.target.value)
                                                   }}
                                                   style={{margin: '10px'}}/>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            style={{background: '#35A236FF'}}
                                            className={classes.button}
                                            startIcon={<AttachMoneyIcon/>}
                                            onClick={() => handelPayment(method.title, totalAfterDiscount, true)}
                                        >{t('Full')} {t(method.title)}</Button>
                                    </TableCell>
                                ))}
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
                <TextField id="discount" name="discount" label={t('Discount')} type="text" variant="outlined"
                           value={discount} onFocus={e => e.target.select()}
                           onChange={(e, value) => {
                               setDiscount(e.target.value)
                           }}
                           style={{margin: '10px', maxWidth: '80px'}}/>
                <TextField id="discountPercentage" label={`${t('Discount')}%`} type="text" variant="outlined"
                           value={discountPercentage} onFocus={e => e.target.select()}
                           onChange={(e, value) => {
                               setDiscountPercentage(e.target.value)
                           }}
                           style={{margin: '10px', maxWidth: '80px'}}/>
                <TextField id="totalPayment" name='totalPayment' label={t('Paid_Total')} type="text" variant="outlined"
                           value={totalPayment}
                           style={{margin: '10px'}}/>
                <TextField id="balance" label={t('Balance')} value={balance} type="text" variant="outlined"
                           style={{margin: '10px'}}/>
                <Button
                    disabled={balance > 0}
                    size="large"
                    variant="contained"
                    color="primary"
                    style={{background: '#35A236FF'}}
                    className={classes.button}
                    startIcon={<PrintIcon/>}
                    onClick={props.handleSubmit}
                >{t('Save')} & {t('Print')} </Button>

            </form>

        </div>
    );
}

export default PaymentPage