
import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import {useDispatch, useSelector} from 'react-redux'
import {createCategory, updateCategory} from '../../actions/categoryActions'
import {useSnackbar} from 'react-simple-snackbar'
import {useTranslation, Trans} from 'react-i18next';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: '#1976D2',
        marginLeft: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'white',
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const AddCategory = ({setOpen, open, currentId, setCurrentId}) => {
    const location = useLocation()
    const [categoryData, setCategoryData] = useState({category: '', creator: ''})
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
    const dispatch = useDispatch()
    const selectedCategory = useSelector((state) => currentId ? state.categories.categories.find((c) => c._id === currentId) : null)
    // eslint-disable-next-line 
    const [openSnackbar, closeSnackbar] = useSnackbar()
    const {t, i18n} = useTranslation();

    useEffect(() => {
        if (selectedCategory) {
            setCategoryData(selectedCategory)
        }
        else {
            clear()
        }
    }, [currentId,selectedCategory])

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile')))
    }, [location])


    useEffect(() => {
        let checkId = user?.result?._id
        if (checkId !== undefined) {
            setCategoryData({...categoryData, creator: checkId})
        } else {
            setCategoryData({...categoryData, creator: user?.result?.googleId})
        }
        console.log(categoryData)
    }, [location])


    const handleSubmitCategory = (e) => {
        e.preventDefault()

        if (!categoryData.category) {

            return;
        }
        let checkId = user?.result?._id
        if (checkId !== undefined) {
            setCategoryData({...categoryData, creator: checkId})
        } else {
            setCategoryData({...categoryData, creator: user?.result?.googleId})
        }
        console.log(user)
        if (currentId) {
            dispatch(updateCategory(currentId, categoryData, openSnackbar))
        } else {
            dispatch(createCategory(categoryData, openSnackbar))
        }

        clear()
        setOpen(false);
    }

    const clear = () => {
        //setCurrentId(null)
        let checkId = user?.result?._id
        if (checkId !== undefined) {
            setCategoryData({category: '', creator: checkId})
        } else {
            setCategoryData({category: '', creator: user?.result?.googleId})
        }
        

    }

    const handleClose = () => {
        setOpen(false);
    };

    const inputStyle = {
        display: "block",
        padding: "1.4rem 0.75rem",
        width: "100%",
        fontSize: "0.8rem",
        lineHeight: 1.25,
        color: "#55595c",
        backgroundColor: "#fff",
        backgroundImage: "none",
        //backgroundClip: "padding-box",
        //borderTop: "0",
        //borderRight: "0",
        border: "1px solid #eee",
        //borderLeft: "0",
        direction: (i18n.resolvedLanguage === 'ar') ? ' rtl ' : ' ',
        borderRadius: "3px",
        transition: "all 0.25s cubic-bezier(0.4, 0, 1, 1)"
    }


    return (
        <div>
            <form>
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title"  style={{direction:i18n.resolvedLanguage === 'ar'? 'rtl' : 'ltr'}} open={open} fullWidth>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}
                                 style={{paddingLeft: '20px', color: 'white'}}>
                        {currentId ? 'Edit Category ' : 'Add new Category'}
                    </DialogTitle>
                    <DialogContent dividers>


                        <div className="customInputs">
                            <input
                                placeholder={t('Name')}
                                style={inputStyle}
                                name='category'
                                type='text'
                                onChange={(e) => setCategoryData({...categoryData, category: e.target.value})}
                                value={categoryData.category}
                            />

                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSubmitCategory} variant="contained" style={{marginRight: '25px'}}>
                            {t('Save')}
                        </Button>
                    </DialogActions>
                </Dialog>

            </form>
        </div>
    );
}

export default AddCategory