import React, {useState, useCallback, useEffect, useRef} from "react";
import {useDropzone} from "react-dropzone";
import styles from './Uploader.module.css'
import {Grid, LinearProgress} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {useTranslation, Trans} from 'react-i18next';
import {uploadFile} from "../../../actions/upload";
import {useSelector} from 'react-redux'
import {useSnackbar} from 'react-simple-snackbar'
import {useDispatch} from 'react-redux';
import {useLocation} from "react-router-dom";
import Pica from 'pica'
import resizeImage from '../../../utils/resizeImage'
const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 5,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#008d3f;',
    },
}))(LinearProgress);

export default function Uploader({setUploadedFile} , props) {
    const [file, setFile] = useState();
    const location = useLocation()
    const [progress, setProgress] = useState(0)
    const dispatch = useDispatch();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
    const [fileData, setFileData] = useState({name: '', type: '', description: '', email: 'opopp@sss.ss'})

    const fileDataRef = useRef(fileData);
    const pica = new Pica();
    useEffect(() => {
        fileDataRef.current = fileData;
    }, [fileData]);

    useEffect(() => {
        setUploadedFile(file)
        //setForm({...form, logo: file})
        // eslint-disable-next-line
    }, [file])

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile')))
        console.log(JSON.parse(localStorage.getItem('profile')))
        // setClientData({...clientData, userId: user?.result?._id})
    }, [])

    useEffect(() => {
        let checkId = user?.result?._id
        if (checkId !== undefined) {
            setFileData({...fileData, userId: [checkId]})
        } else {
            setFileData({...fileData, userId: [user?.result?.googleId]})
        }

    }, [user])

    const onDrop = useCallback((acceptedFiles) => {

        const url = "https://api.cloudinary.com/v1_1/almpo/image/upload";

        function toBuffer(ab) {
            //From ArrayBuffer to Buffer:
            const buf = Buffer.alloc(ab.byteLength);
            const view = new Uint8Array(ab);
            for (let i = 0; i < buf.length; ++i) {
                buf[i] = view[i];
            }
            return buf;
        }

        function toArrayBuffer(buf) {
            //From Buffer to ArrayBuffer:
            const ab = new ArrayBuffer(buf.length);
            const view = new Uint8Array(ab);
            for (let i = 0; i < buf.length; ++i) {
                view[i] = buf[i];
            }
            return ab;
        }
        setProgress(100)
        acceptedFiles.forEach(async (file) => {
            console.log("ddddddd")
            await resizeImage(file, 150).then(async (buf) => {
                console.log(buf)
                const response2 = await dispatch(uploadFile({
                    ...fileDataRef.current,
                    'bodyData': buf
                }));

                console.log("done")
                setFile(response2?._id)
                console.log((response2))

            })

            return
            //   const { signature, timestamp } = await getSignature();
            const get_file_array = (file) => {
                // Resize & convert to blob


                return new Promise((acc, err) => {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        acc(event.target.result)
                    };
                    reader.onerror = (err) => {
                        err(err)
                    };
                    reader.readAsArrayBuffer(file);
                });
            }
            setProgress(100)
            const temp = await get_file_array(file)
            console.log(temp);
            console.log(fileDataRef.current);
            console.log("0000000000000000000000000000000000000000000000");
            const response = await dispatch(uploadFile({
                ...fileDataRef.current,
                'bodyData': toBuffer(temp)
            }));


            // const data = await response.json();

            // setFile(data.secure_url)
            //setFile("")
            setFile(response?._id)
            console.log((response))
            //console.log(toBuffer(temp).toString('base64'))
        });
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accepts: "image/*,application/pdf",
        multiple: false,
    });
    const {t, i18n} = useTranslation();

    return (
        <>
            <div style={{border:0,margin:0,padding:0,height:'unset'}}
                {...getRootProps()}
                className={`${styles.dropzone} ${isDragActive ? styles.active : null}`}
            >
                <input {...getInputProps()}  />
                {t('Upload_Logo')}
            </div>
            <Grid item style={{width: '100%'}}>
                <BorderLinearProgress variant="determinate" value={progress}/>
            </Grid>
        </>
    );
}

