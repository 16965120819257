import Barcode from "react-barcode";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {CalcPriceWithTax, toFixedFloat} from "../../utils/utils";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import QRCode from "qrcode.react";
import React, {useRef} from "react";
import styles from './pos.module.css'
import {useTranslation} from "react-i18next";

const Card = (props) => {
    const {t, i18n} = useTranslation();
    const cardContainer = useRef(null);
    return (
        <div className={[styles.cardContainer, styles.noSelect].join(' ')}>
            <style>
                {
                `
                   @page {
                    padding:0;
                    margin-top:0;
                    margin-bottom: 0;
                    margin-left: 0;
                    margin-right:0;
                }
                @media print {
                  @page {
                    size: 80mm ${(cardContainer?.current?.scrollHeight + 550)}px;
                 }
                }
                `
                }
            </style>
            <table style={{width: '100%'}} border="0" className={styles.np}>
                <thead>
                <tr style={{borderBottom: '1px solid #c3bdbd'}}>
                    <td width='80'></td>
                    <td width="30%">{t('Name')}</td>
                    <td>{t('Price')}</td>
                    <td>{t('Count')}</td>
                    <td>{t('Total')} </td>
                    <td></td>
                </tr>
                </thead>
            </table>
            <div id="card" className={props?.cardReceipt||styles.card} ref={cardContainer}>
                <div style={{textAlign: 'center'}} className={props?.print||styles.printOnly}>
                    {props.logoPath && <img src={props.logoPath} alt="Logo" className={styles.logo}/>}
                    {props.profiles?.businessName && <p>{props.profiles?.businessName}</p>}
                    {props.profiles?.commercialRecord &&
                        <p>{t('Commercial_Record')}:{props.profiles?.commercialRecord}</p>}
                    {props.profiles?.vatRecord && <p>{t('VAT_Record')}:{props.profiles?.vatRecord}</p>}
                    {t("Date")}:{new Date().toLocaleDateString()} &nbsp;&nbsp; {new Date().toLocaleTimeString()}
                    <hr/>
                    {t("No")}:{props.lastInvoiceNumber}
                    <div style={{display: 'block', clear: 'both'}}>
                        <Barcode value={props.lastInvoiceNumber} height="20" format="CODE128" displayValue="false"/>
                    </div>
                    <hr/>
                </div>

                <table style={{width: '100%'}} border="0">
                    <tbody id="ordertable">
                    <tr style={{borderBottom: '1px solid #c3bdbd'}} className={props?.print||styles.printOnlyRow}>
                        <td className={props?.print?styles.hide:styles.np}></td>
                        <td>{t('Name')}</td>
                        <td>{t('Price')}</td>
                        <td>{t('Count')}</td>
                        <td>{t('Total')}</td>
                        <td className={props?.print?styles.hide:styles.np}></td>
                    </tr>
                    {props.uniqueCard.map((row) => (

                        <tr>
                            <td className={props?.print?styles.hide:styles.np}><AddCircleIcon
                                onClick={() => props.addToCard(row, false)}
                                style={{color: '#10bd10', fontSize: '40px'}}/>
                            </td>
                            <td width="50%">{row?.shortName}</td>
                            <td>{CalcPriceWithTax(row, props.profiles?.vatPercentage)}</td>
                            <td width='40'>&nbsp;{row?.count}</td>
                            <td>{toFixedFloat(row?.total)}</td>
                            <td className={props?.print?styles.hide:styles.np}><RemoveCircleIcon
                                onClick={() => props.removeFromCard(row)}
                                style={{color: '#ff1818', fontSize: '40px'}}/></td>
                        </tr>
                    ))}

                    </tbody>
                </table>
                <div className={props?.print||styles.printOnly}>
                    {(props.saudiQrCode) &&
                        <QRCode value={props.saudiQrCode} fgColor="#13388c" renderAs="svg"
                                style={{display: 'block', margin: '10px', marginLeft: 'auto', marginRight: 'auto'}}/>}
                    {(!props.saudiQrCode) && <QRCode
                        value={window.location.origin + '/view/receipt/id...'} renderAs="svg"
                        style={{display: 'block', margin: '10px', marginLeft: 'auto', marginRight: 'auto'}}/>}
                    <hr/>
                    {props.paymentData.map(method => (
                        (method.paidAmount > 0) && <span style={{
                            minWidth: '140px',
                            display: 'inline-block'
                        }}>&nbsp;&nbsp;{t(method.title)}:{method.paidAmount} &nbsp;&nbsp;</span>
                    ))}

                    {(props.paymentInfo.total !== 0) && <p>{t('Total')}:{props.paymentInfo.total}</p>}
                    {(props.paymentInfo.totalDiscount !== 0) &&
                        <p>{t('Discount')}:{props.paymentInfo.totalDiscount}</p>}
                    {(props.paymentInfo.totalAfterDiscount !== 0) &&
                        <p>{t('Amount_Due')}:{props.paymentInfo.totalAfterDiscount}</p>}
                    {(props.paymentInfo.totalPayment !== 0) && <p>{t('Paid')}:{props.paymentInfo.totalPayment}</p>}
                    {(props.paymentInfo.totalTax !== 0) && <p>{t('VAT')}:{props.paymentInfo.totalTax}</p>}
                    <p>{t('Balance')}:{props.paymentInfo.balance}</p>
                    {props.client && <div>
                        <hr/>
                        {t('Customer')}:{props.client.name} - {props.client.email} - {props.client.phone} - {props.client.address}
                    </div>}
                    <hr/>
                </div>
                <div style={{textAlign: 'center'}} className={props?.print||styles.printOnly}>
                    {(props.profiles?.contactAddress && <p>{props.profiles?.contactAddress}</p>)}
                    {(props.profiles?.email && <p>{props.profiles?.email}</p>)}
                    {(props.profiles?.phoneNumber && <p>{props.profiles?.phoneNumber}</p>)}
                </div>
            </div>
        </div>
    )

}
export default Card