import React, {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import styles from './Footer.module.css'
import FabButton from '../Fab/Fab'
import {useTranslation, Trans} from 'react-i18next';

const Footer = () => {
    const location = useLocation()
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
    const {t, i18n} = useTranslation();

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile')))
    }, [location])

    return (<footer className="np">
            <div className={styles.footerText}>
                {t('©Quantum_Billing|_made_with_♥_by_Quantum_information_technology_limited_(QITL)')}
                <span>
                <a href="tel://+1361-405-5427"   rel="noopener noreferrer"  className={styles.phoneNumber} >  +1361-405-5427 </a></span>
            </div>
            {user && (<FabButton/>)}
        </footer>)
}

export default Footer
