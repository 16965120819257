import pica from 'pica'

function toBuffer(ab) {
    //From ArrayBuffer to Buffer:
    const buf = Buffer.alloc(ab.byteLength);
    const view = new Uint8Array(ab);
    for (let i = 0; i < buf.length; ++i) {
        buf[i] = view[i];
    }
    return buf;
}

export default async function resizeImage(imageFile, resize_width = 150, resize_height = 0) {

    return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onload = function (event) {
            let img = new Image();
            img.src = event.target.result;
            img.onload = async function (el) {
                let resizedCanvas = document.createElement('canvas');//create a canvas
                let scaleFactor = resize_width / el.target.width;
                resizedCanvas.width = resize_width;
                resizedCanvas.height = resize_height || el.target.height * scaleFactor;
                let ctx = resizedCanvas.getContext('2d');
                ctx.drawImage(el.target, 0, 0, resizedCanvas.width, resizedCanvas.height);
                pica().resize(img, resizedCanvas, {
                    unsharpAmount: 80, unsharpRadius: 0.6, unsharpThreshold: 2
                }).then(result => pica().toBlob(result, 'image/png', 0.90))
                    .then((blob) => {
                        const reader = new FileReader();
                        reader.addEventListener('loadend', async () => {
                            const arrayBuffer = reader.result;
                            //callBack(toBuffer(arrayBuffer))
                            resolve(toBuffer(arrayBuffer))
                        });
                        reader.readAsArrayBuffer(blob);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err)
                    });
            }
        }
    });
}
