import React from 'react'
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'
import SnackbarProvider from 'react-simple-snackbar'
import Home from './components/Home/Home';
import Invoice from './components/Invoice/Invoice';
import Invoices from './components/Invoices/Invoices';
import InvoiceDetails from './components/InvoiceDetails/InvoiceDetails'
import ClientList from './components/Clients/ClientList'
import NavBar from './components/NavBar/NavBar';
import Login from './components/Login/Login'
import Dashboard from './components/Dashboard/Dashboard';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Settings from './components/Settings/Settings';
import Forgot from './components/Password/Forgot'
import Reset from './components/Password/Reset'
import {useTranslation, Trans} from 'react-i18next';
import AddCategory from "./components/Categories/AddCategory";
import Categories from "./components/Categories/Categories";
import Items from "./components/Items/Items";
import POS from "./components/POS/pos";
import RTL from "./utils/stylesprovider";
import Receipt from "./components/POS/receipt";
import Register from "./components/Login/Register";
import Prices from "./components/Prices/Prices";
import ContactUs from "./components/Contactus/Contactus";
import Download from "./components/Download/Download";

function App() {
    const {t, i18n} = useTranslation();
    const user = JSON.parse(localStorage.getItem('profile'))

    return (
        <RTL isRTL={i18n.resolvedLanguage === 'ar'}>
            <div dir={(i18n.resolvedLanguage === 'ar') ? 'rtl' : 'ltr'}>
                <BrowserRouter>
                    <SnackbarProvider>
                        {user && <NavBar/>}
                        <Header/>
                        <Switch>
                            <Route path="/pos" exact component={POS}/>
                            <Route path="/pos/:id" exact component={Receipt}/>
                            <Route path="/" exact component={Home}/>
                            <Route path="/Prices" exact component={Prices}/>
                            <Route path="/contactus" exact component={ContactUs}/>
                            <Route path="/download" exact component={Download}/>
                            <Route path="/add/invoice" exact component={Invoice}/>
                            <Route path="/edit/invoice/:id" exact component={Invoice}/>
                            <Route path="/invoice/:id" exact component={InvoiceDetails}/>
                            <Route path="/invoices" exact component={Invoices}/>
                            <Route path="/login" exact component={Login}/>
                            <Route path="/signup" exact component={Register}/>
                            <Route path="/settings" exact component={Settings}/>
                            <Route path="/dashboard" exact component={Dashboard}/>
                            <Route path="/customers" exact component={ClientList}/>
                            <Route path="/forgot" exact component={Forgot}/>
                            <Route path="/reset/:token" exact component={Reset}/>
                            <Redirect exact from="/new-invoice" to="/invoice"/>
                            <Route path="/categories/add" exact component={AddCategory}/>
                            <Route path="/categories" exact component={Categories}/>
                            <Route path="/items" exact component={Items}/>

                        </Switch>
                        <Footer/>
                    </SnackbarProvider>
                </BrowserRouter>
            </div>
        </RTL>
    );
}

export default App;
