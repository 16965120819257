export function toCommas(value) {
    if(isNaN(value)) return 0;
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const isNumeric = num => /^-?[0-9]+(?:\.[0-9]+)?$/.test(num+'');

export function toFixedFloat(value) {
    if (isNaN(value) || value === null || value === undefined || !isNumeric(value)) {
        return 0;
    }
    return parseFloat(parseFloat(value).toFixed(2));
}

export function IntToHex(intval) {
    return intval.toString(16);
}

export function hex_to_ascii(hexx) {
    let hex = hexx.toString();//force conversion
    let str = '';
    for (let i = 0; i < hex.length; i += 2)
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;

}

export function GenerateSaudiTaxCode(sellerName, vatNumber, timeStamp, invoiceTotal, vatTotal) {
    let code = ""
    for (let i = 0; i < arguments.length; i++) {
        const argument = arguments[i] ? arguments[i] : ""
        let tageOrder = i + 1;
        let tag = hex_to_ascii(tageOrder.toString().padStart(2, "0"));
        let stringLength = hex_to_ascii(IntToHex(unescape(encodeURIComponent(argument.toString())).length))
        code += tag + stringLength + argument;
    }
    let b64 = btoa(unescape(encodeURIComponent(code + hex_to_ascii("06"))));

    return b64;

}

export  function CalcPriceWithTax(row , tax ){
   return  (row?.priceIncludesTax) ? _CalcPriceWithTaxIncluded(row , tax) : _CalcPriceWithTax(row , tax);
}


function _CalcPriceWithTaxIncluded(row , tax){
    return  row?.sellingPrice;
}

function _CalcPriceWithTax(row , tax){
    return  (row?.enableTax) ? toFixedFloat(row?.sellingPrice + toFixedFloat(row?.sellingPrice * tax / 100)) : row?.sellingPrice;
}


export  function CalcTax(row , tax ){
    return  (row?.priceIncludesTax) ? _CalcTaxIncluded(row , tax) : _CalcTax(row , tax);
}


function _CalcTaxIncluded(row , tax){
    return  (row?.enableTax) ? toFixedFloat(row?.sellingPrice) - toFixedFloat(row?.sellingPrice /(1 +  (tax / 100))) : 0;
}

function _CalcTax(row , tax){
    return  (row?.enableTax) ? toFixedFloat(row?.sellingPrice * tax / 100) : 0;
}


