import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Suspense} from 'react';
import {createStore, applyMiddleware, compose} from 'redux'
import {Provider} from 'react-redux'
import thunk from 'redux-thunk'
import reducers from './reducers/'
import './i18n';

const store = createStore(reducers, applyMiddleware(thunk))


ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback="...is loading">
            <App/>
        </Suspense>
    </Provider>,
    document.getElementById('root')
);