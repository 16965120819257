import {
    START_LOADING,
    END_LOADING,
    CREATE_ITEM,
    UPDATE_ITEM,
    DELETE_ITEM,
    FETCH_ITEM,
    FETCH_ITEMS_BY_USER,
    FIND_ITEM_BY_BARCODE,
    ITEM_CATEGORY
} from '../actions/constants';

const items = (state = {isLoading: true, items: [],category:'',barcodeItems:null}, action) => {
    switch (action.type) {
        case START_LOADING:
            return {...state, isLoading: true};
        case END_LOADING:
            return {...state, isLoading: false};
        case FETCH_ITEMS_BY_USER:
            return {...state, items: action.payload.items, numberOfPages: action.payload.numberOfPages};
        case FETCH_ITEM:
            return {...state, item: action.payload.item};
        case ITEM_CATEGORY:
            return {...state, category: action.payload};
        case FIND_ITEM_BY_BARCODE:
            return {...state, barcodeItems: action.payload};
        case CREATE_ITEM:
            return {...state, items: [...state.items, action.payload]};
        case UPDATE_ITEM:
            return {
                ...state,
                items: state.items.map((item) => (item._id === action.payload._id ? action.payload : item))
            };
        case DELETE_ITEM:
            return {...state, items: state.items.filter((client) => client._id !== action.payload)};
        default:
            return state;
    }
};

export default items

