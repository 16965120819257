import React, {useState} from 'react'
import {useTranslation, Trans} from 'react-i18next';
import {Button, Container, IconButton, Paper, Snackbar, TableCell, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import {useDispatch, useSelector} from "react-redux";
import Spinner from "../Spinner/Spinner";

import MuiAlert from '@material-ui/lab/Alert';
import styles from "../Home/Home.module.css";

const useStyles = makeStyles({
    root: {
        '& > *': {
            margin: '10px',
            width: '100%',
        },
    },
    button: {
        margin: '20px',
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ContactUs = () => {
    const {t, i18n} = useTranslation();
    const {isLoading} = useSelector((state) => state.contactus)


    if (isLoading) {
        return <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingTop: '20px'
        }}>
            <Spinner/>
        </div>
    }


    return (
        <Container style={{backgroundColor: '#ffffff', marginTop:'70px'}} >

            <div style={{padding:'50px'}} >
                <p>
                In general you don't need to download any thing to work with quantum billing and you can work directly from your
                internet browser, in case you need a windows client you can download the POS client from the following link
                </p>
                <p>&nbsp;</p>
                <p>
                <a href="https://www.dropbox.com/s/f8zmw3s6x8a2wf0/quantum-billing-setup.exe?dl=1"  style={{textDecoration:'none'}}>
                    Click here to download windows POS client
                <IconButton>
                     <DownloadIcon>Click here to download windows POS client</DownloadIcon>
                </IconButton>
                </a>
                </p>
                <p>&nbsp;</p>

                <img src="/images/windows-client.png" className={["img-responsive", styles.screenImages].join(' ')} style={{marginBottom:'30px'}}/>
            </div>
        </Container>
    )
}

export default ContactUs
