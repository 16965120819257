import React, {useState} from 'react'
import Field from './Field'
import useStyles from './styles'
import styles from './Login.module.css'
import {GoogleLogin} from 'react-google-login'
import {useDispatch} from 'react-redux'
import {useHistory, Link} from 'react-router-dom'
import {signup, signin} from '../../actions/auth'
import {Avatar, Button, Paper, Grid, Typography, Container} from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import {createProfile} from '../../actions/profile'
// import Google from './Google'
import {useSnackbar} from 'react-simple-snackbar'
import ProgressButton from 'react-progress-button'
import {useTranslation, Trans} from 'react-i18next';
import Login from "./Login";


const Register = () => {

    return (
        <Login isSignup={true} />
    )
}

export default Register
