import React, {useState} from 'react'
import {useTranslation, Trans} from 'react-i18next';
import {Button, Container, Paper, Snackbar, TableCell, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from "@material-ui/icons/Delete";
import TelegramIcon from '@material-ui/icons/Telegram';
import {createItem, updateItem} from "../../actions/itemActions";
import {contactUs} from "../../actions/contactus";
import {useDispatch, useSelector} from "react-redux";
import Spinner from "../Spinner/Spinner";

import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
    root: {
        '& > *': {
            margin: '10px',
            width: '100%',
        },
    },
    button: {
        margin: '20px',
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ContactUs = () => {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const {isLoading} = useSelector((state) => state.contactus)
    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("");
    const [formData, setFormData] = useState({
        yourName: '',
        yourEmail: '',
        yourMessage: '',
        date: new Date().toISOString()
    })
    const handleChange = (e) => {
        (e.target.type === 'checkbox') ?
            setFormData({...formData, [e.target.name]: e.target.checked}) :
            setFormData({...formData, [e.target.name]: e.target.value});
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        if (severity === 'success') {
            setFormData({
                yourName: '',
                yourEmail: '',
                yourMessage: '',
                date: new Date().toISOString()
            });
        }
        setOpen(false);
    };

    const feedBack = (message) => {
        setSeverity(message.toString().toLowerCase().includes('error')?"error": "success");
        setMessage(message.toString());
        setOpen(true);
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(contactUs(formData, feedBack))
    }

    if (isLoading) {
        return <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingTop: '20px'
        }}>
            <Spinner/>
        </div>
    }


    return (
        <Container style={{backgroundColor: '#ffffff', marginTop:'70px'}}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>

            <form className={classes.root} noValidate autoComplete="off">
                <p>&nbsp;</p>
                <p>
                    <a href="https://telegram.me/QuantumBilling" style={{textDecoration: 'none'}}> Click here to send us
                        Telegram Message <TelegramIcon style={{color: '#519dfb'}}/> +1361-405-5427 </a>
                </p>
                <TextField id="yourName" name="yourName" onChange={handleChange} autoComplete="off"
                           value={formData.yourName} label="Your Name" variant="outlined"/>
                <TextField id="yourEmail" name="yourEmail" onChange={handleChange} autoComplete="off"
                           value={formData.yourEmail} label="Your Email" variant="outlined" type="email"/>
                <TextField
                    id="yourMessage" name="yourMessage"
                    onChange={handleChange} autoComplete="off"
                    value={formData.yourMessage}
                    label="Your Message"
                    multiline
                    rows={10}
                    variant="outlined"
                />
                <div>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        startIcon={<DeleteIcon/>}
                        type='reset'
                    >
                        Cancel
                    </Button>
                    {/* This Button uses a Font Icon, see the installation instructions in the Icon component docs. */}
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<SendIcon/>}
                        onClick={handleSubmit}
                    >
                        Send
                    </Button>
                </div>
            </form>
        </Container>
    )
}

export default ContactUs
