export const features = [
    ['Feature',
        'Free',
        'Silver',
        'Golden'
    ],

    ['Price',
        'Free',
        '$5/Month',
        '$10/Month'
    ],
    ['Create Invoices ',
        '✔',
        '✔',
        '✔'
    ],

    ['Create Receipt ',
        '✔',
        '✔',
        '✔'
    ],

    ['Create Quotations ',
        '✔',
        '✔',
        '✔'
    ],

    ['Create Estimate ',
        '✔',
        '✔',
        '✔'
    ],
    ['Create Bill ',
        '✔',
        '✔',
        '✔'
    ],

    ['Add your company logo ',
        '✔',
        '✔',
        '✔'
    ],

    ['Add your company Data ',
        '✔',
        '✔',
        '✔'
    ],

    ['Create QR code for invoices ',
        '✔',
        '✔',
        '✔'
    ],

    ['Record payments ',
        '✔',
        '✔',
        '✔'
    ],

    ['Google login',
        '✔',
        '✔',
        '✔'
    ],

    ['Normal login',
        '✔',
        '✔',
        '✔'
    ],

    ['Multiple currencies',
        '✔',
        '✔',
        '✔'
    ],

    ['Record VAT value',
        '✔',
        '✔',
        '✔'
    ],

    ['List customers',
        '✔',
        '✔',
        '✔'
    ],

    ['List invoices',
        '✔',
        '✔',
        '✔'
    ],

    ['Delete invoices',
        '✔',
        '✔',
        '✔'
    ],

    ['Apply discounts per item',
        '✔',
        '✔',
        '✔'
    ],

    ['Mobile version',
        '✔',
        '✔',
        '✔'
    ],

    ['Touch optimized',
        '✔',
        '✔',
        '✔'
    ],

    ['Dashboard',
        '✔',
        '✔',
        '✔'
    ],

    ['Export PDF ',
        '✔',
        '✔',
        '✔'
    ],

    ['Free SSL',
        '✔',
        '✔',
        '✔'
    ],
    ['Send Email',
        '✔',
        '✔',
        '✔'
    ],

    ['Invoice per day ',
        '20',
        '100',
        'Unlimited'
    ],

    ['Email per day ',
        '20',
        '100',
        'Unlimited'
    ],

    ['Keep invoices ',
        '11Month',
        '2Years',
        'Unlimited'
    ],

    ['Keep customers Data  ',
        '11Month',
        '2Years',
        'Unlimited'
    ],

    ['Autocomplete items Data ',
        '10',
        '30',
        'Unlimited'
    ],

    ['Recurring invoice',
        '❌',
        '❌',
        '✔'
    ],

    ['Telephone support',
        '❌',
        '❌',
        '✔'
    ],

    ['Technical help',
        'Website help center',
        'chat',
        'chat and phone'
    ],

    ['Priority support',
        '❌',
        '❌',
        '✔'
    ],
    ['Notification for down time and maintenance',
        '❌',
        '✔',
        '✔'
    ],
    ['Price',
        'Free',
        '$5/Month',
        '$10/Month'
    ],

];