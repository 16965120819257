import React, {useState, useEffect} from 'react'
import styles from './Invoice.module.css'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useParams} from 'react-router-dom'
import moment from 'moment'
import {useHistory} from 'react-router-dom'
import {toCommas, toFixedFloat} from '../../utils/utils'

import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import {Container, Grid} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

import {initialState} from '../../initialState'
import currencies from '../../currencies.json'
import {
    createInvoice,
    getAddNewInvoice,
    getInvoice,
    getLastInvoiceNumberByUser,
    updateInvoice
} from '../../actions/invoiceActions';
import {getClientsByUser} from '../../actions/clientActions'
import AddClient from './AddClient';
import InvoiceType from './InvoiceType';
import {useTranslation, Trans} from 'react-i18next';
// import SelectType from './SelectType'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
    },
    table: {
        minWidth: 650,
    },

    headerContainer: {
        // display: 'flex'
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(1),
    }
}));

const Invoice = () => {

    const {t, i18n} = useTranslation();
    const [invoiceData, setInvoiceData] = useState(initialState)
    const [rates, setRates] = useState(0)
    const [vat, setVat] = useState(0)
    const [currency, setCurrency] = useState(currencies[0].value)
    const [subTotal, setSubTotal] = useState(0)
    const [total, setTotal] = useState(0)
    const today = new Date();
    const [selectedDate, setSelectedDate] = useState(today.getTime() + 7 * 24 * 60 * 60 * 1000);
    const [client, setClient] = useState(null)
    const [type, setType] = React.useState('Invoice')
    const [status, setStatus] = useState('')
    const {id} = useParams()
    const clients = useSelector((state) => state.clients.clients)
    const {invoice} = useSelector((state) => state.invoices);
    const dispatch = useDispatch()
    const history = useHistory()
    const user = JSON.parse(localStorage.getItem('profile'))
    const [lastInvoiceNumber, setLastInvoiceNumber] = useState();
    const location = useLocation()

    useEffect(async () => {
        if (id !== undefined) {
            dispatch(getInvoice(id));
        } else {
            dispatch(getInvoice(id));
            dispatch(getAddNewInvoice());
        }

        // eslint-disable-next-line
    }, [id]);

    useEffect(async () => {
        await dispatch(getClientsByUser({search: user?.result._id || user?.result?.googleId}));
        const lastInvoiceNumberData = await dispatch(getLastInvoiceNumberByUser({search: user?.result._id || user?.result?.googleId}));
        if (lastInvoiceNumberData) {
            setLastInvoiceNumber(parseInt(lastInvoiceNumberData.data) + 1);
        } else {
            setLastInvoiceNumber(Math.floor(Math.random() * 100000));
        }
        if ((invoice?.invoiceNumber) && id) {
            setLastInvoiceNumber(invoice?.invoiceNumber)
        }
    }, [dispatch, location]);


    useEffect(() => {
        if ((invoice?.type) && id) {
            //Automatically set the default invoice values as the ones in the invoice to be updated
            setInvoiceData(invoice)
            setRates(invoice?.rates)
            setClient(invoice?.client)
            setType(invoice?.type)
            setStatus(invoice?.status)
            setSelectedDate(invoice?.dueDate)
            setLastInvoiceNumber(invoice?.invoiceNumber)
        } else {
            setInvoiceData({
                ...initialState, items: [
                    {itemName: '', unitPrice: '', quantity: '', discount: ''},
                ]
            })
        }

    }, [invoice])


    useEffect(() => {
        if (type === 'Receipt') {
            setStatus('Paid')
        } else {
            setStatus('Unpaid')
        }
    }, [type])

    const defaultProps = {
        options: currencies,
        getOptionLabel: (option) => option.label
    };

    const clientsProps = {
        options: clients,
        getOptionLabel: (option) => option.name
    };


    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleRates = (e) => {
        setRates(e.target.value)
        setInvoiceData((prevState) => ({...prevState, tax: e.target.value}))
    }

    // console.log(invoiceData)
    // Change handler for dynamically added input field
    const handleChange = (index, e) => {
        const values = [...invoiceData.items]
        values[index][e.target.name] = e.target.value
        setInvoiceData({...invoiceData, items: values})

    }

    useEffect(() => {
        //Get the subtotal
        const subTotal = () => {
            var arr = document.getElementsByName("amount");
            var subtotal = 0;
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].value) {
                    subtotal += +arr[i].value;
                }
                // document.getElementById("subtotal").value = subtotal;
                setSubTotal(toFixedFloat(subtotal))
            }
        }

        subTotal()

    }, [invoiceData])


    useEffect(() => {
        const total = () => {

            //Tax rate is calculated as (input / 100 ) * subtotal + subtotal 
            const overallSum = rates / 100 * subTotal + subTotal
            //VAT is calculated as tax rates /100 * subtotal
            setVat(toFixedFloat(rates / 100 * subTotal))
            setTotal(toFixedFloat(overallSum))


        }
        total()
    }, [invoiceData, rates, subTotal])


    const handleAddField = (e) => {
        e.preventDefault()
        setInvoiceData((prevState) => ({
            ...prevState,
            items: [...prevState.items, {itemName: '', unitPrice: '', quantity: '', discount: '', amount: ''}]
        }))
    }

    const handleRemoveField = (index) => {
        const values = invoiceData.items
        values.splice(index, 1)
        setInvoiceData((prevState) => ({...prevState, values}))
        // console.log(values)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (id !== undefined) {
            dispatch(updateInvoice(invoice._id, {
                ...invoiceData,
                subTotal: subTotal,
                total: total,
                vat: vat,
                rates: rates,
                currency: currency,
                dueDate: selectedDate,
                client,
                type: type,
                invoiceNumber: lastInvoiceNumber,
                status: status
            }))

            history.push(`/invoice/${invoice._id}`)
        } else {

            dispatch(createInvoice({
                    ...invoiceData,
                    subTotal: subTotal,
                    total: total,
                    vat: vat,
                    rates: rates,
                    currency: currency,
                    dueDate: selectedDate,
                    client,
                    type: type,
                    invoiceNumber: lastInvoiceNumber,
                    status: status,
                    paymentRecords: [],
                    creator: [user?.result?._id || user?.result?.googleId]
                },
                history
            ))

        }

        setInvoiceData({
            ...initialState, items: [
                {itemName: '', unitPrice: '', quantity: '', discount: ''},
            ]
        })
    }

    const classes = useStyles()
    const [open, setOpen] = useState(false);

    const CustomPaper = (props) => {
        return <Paper elevation={3} {...props} />;
    };


    if (!user) {
        history.push('/login')
    }


    return (
        <div className={styles.invoiceLayout}>
            <form onSubmit={handleSubmit} className="mu-form">
                <AddClient setOpen={setOpen} open={open}/>
                <Container className={classes.headerContainer}>

                    <Grid container justifyContent="space-between">
                        <Grid item>
                            {/* <Avatar alt="Logo" variant='square' src="" className={classes.large} /> */}
                        </Grid>
                        <Grid item>
                            {/* <div style={{paddingTop: '20px'}}>
                            <SelectType  type={type} setType={setType} />
                        </div> */}
                            <InvoiceType type={t(type)} setType={setType}/>
                            <Typography variant="overline" style={{color: 'gray'}}>{t('Number')}#:</Typography>
                            {lastInvoiceNumber} {/* <InputBase defaultValue={lastInvoiceNumber}/>*/}
                        </Grid>
                    </Grid>
                </Container>
                <Divider/>
                <Container>
                    <Grid container justifyContent="space-between" style={{marginTop: '40px'}}>
                        <Grid item style={{width: '50%'}}>
                            <Container>
                                <Typography variant="overline" style={{color: 'gray', paddingRight: '3px'}}
                                            gutterBottom>Bill to</Typography>


                                {client && (
                                    <>
                                        <Typography variant="subtitle2" gutterBottom>{client.name}</Typography>
                                        <Typography variant="body2">{client.email}</Typography>
                                        <Typography variant="body2">{client.phone}</Typography>
                                        <Typography variant="body2">{client.address}</Typography>
                                        <Button color="primary" size="small" style={{textTransform: 'none'}}
                                                onClick={() => setClient(null)}>{t('Change')}</Button>
                                    </>
                                )}
                                <div style={client ? {display: 'none'} : {display: 'block'}}>
                                    <Autocomplete
                                        {...clientsProps}
                                        PaperComponent={CustomPaper}
                                        renderInput={(params) => <TextField {...params}
                                                                            required={!invoice && true}
                                                                            label={t('Select_Customer')}
                                                                            margin="normal"
                                                                            variant="outlined"
                                        />}
                                        value={clients?.name}
                                        onChange={(event, value) => setClient(value)}

                                    />

                                </div>
                                {!client &&
                                    <>
                                        <Grid item style={{paddingBottom: '10px'}}>
                                            <Chip
                                                avatar={<Avatar>+</Avatar>}
                                                label={t('New_Customer')}
                                                onClick={() => setOpen(true)}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </>
                                }
                            </Container>
                        </Grid>

                        <Grid item style={{marginRight: 20, textAlign: 'right'}}>
                            <Typography variant="overline" style={{color: 'gray'}}
                                        gutterBottom>{t('Status')}</Typography>
                            <Typography variant="h6" gutterBottom
                                        style={{color: (type === 'Receipt' ? 'green' : 'red')}}>{(type === 'Receipt' ? 'Paid' : 'Unpaid')}</Typography>
                            <Typography variant="overline" style={{color: 'gray'}} gutterBottom>{t('Date')}</Typography>
                            <Typography variant="body2" gutterBottom>{moment().format("DD/MM/YYYY")}</Typography>
                            <Typography variant="overline" style={{color: 'gray'}}
                                        gutterBottom>{t('Due_Date')}</Typography>
                            <Typography variant="body2"
                                        gutterBottom>{selectedDate ? moment(selectedDate).format("DD/MM/YYYY") : '25/01/2021'}</Typography>
                            <Typography variant="overline" gutterBottom>{t('Amount')}</Typography>
                            <Typography variant="h6" gutterBottom>{currency} {toCommas(total)}</Typography>
                        </Grid>
                    </Grid>
                </Container>


                <div>

                    <TableContainer component={Paper} className="tb-container">
                        <Table className={(i18n.resolvedLanguage === 'ar') ? ' rtl ' : ' ' + classes.table}
                               aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('Item')}</TableCell>
                                    <TableCell>{t('Qty')}</TableCell>
                                    <TableCell>{t('Price')}</TableCell>
                                    <TableCell>{t('Disc')}(%)</TableCell>
                                    <TableCell>{t('Amount')}</TableCell>
                                    <TableCell>{t('Action')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(invoiceData?.items) && invoiceData.items.map((itemField, index) => (
                                    <TableRow key={index}>
                                        <TableCell scope="row" style={{width: '40%'}}> <InputBase
                                            style={{width: '100%'}} outline="none" sx={{ml: 1, flex: 1}} type="text"
                                            name="itemName" onChange={e => handleChange(index, e)}
                                            value={itemField.itemName} placeholder={t('Item_name_or_description')}/>
                                        </TableCell>
                                        <TableCell align="right"> <InputBase sx={{ml: 1, flex: 1}} type="number"
                                                                             name="quantity"
                                                                             onChange={e => handleChange(index, e)}
                                                                             value={itemField.quantity}
                                                                             placeholder="0"/> </TableCell>
                                        <TableCell align="right"> <InputBase sx={{ml: 1, flex: 1}} type="number"
                                                                             name="unitPrice"
                                                                             onChange={e => handleChange(index, e)}
                                                                             value={itemField.unitPrice}
                                                                             placeholder="0"/> </TableCell>
                                        <TableCell align="right"> <InputBase sx={{ml: 1, flex: 1}} type="number"
                                                                             name="discount"
                                                                             onChange={e => handleChange(index, e)}
                                                                             value={itemField.discount}
                                                                             placeholder="0"/> </TableCell>
                                        <TableCell align="right"> <InputBase sx={{ml: 1, flex: 1}} type="number"
                                                                             name="amount"
                                                                             onChange={e => handleChange(index, e)}
                                                                             value={(itemField.quantity * itemField.unitPrice) - (itemField.quantity * itemField.unitPrice) * itemField.discount / 100}
                                                                             disabled/> </TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleRemoveField(index)}>
                                                <DeleteOutlineRoundedIcon style={{width: '20px', height: '20px'}}/>
                                            </IconButton>
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={styles.addButton}>
                        <button onClick={handleAddField}>+</button>
                    </div>
                </div>

                <div className={styles.invoiceSummary}>
                    <div className={styles.summary}>{t('Invoice_Summary')}</div>
                    <div className={styles.summaryItem}>
                        <p>{t('Sub_total')}:</p>
                        <h4>{subTotal}</h4>
                    </div>
                    <div className={styles.summaryItem}>
                        <p>{t('VAT(%)')}:</p>
                        <h4>{vat}</h4>
                    </div>
                    <div className={styles.summaryItem}>
                        <p>{t('Total')}</p>
                        <h4 style={{
                            color: "black",
                            fontSize: "18px",
                            lineHeight: "8px"
                        }}>{currency} {toCommas(total)}</h4>
                    </div>

                </div>


                <div className={styles.toolBar}>
                    <Container>
                        <Grid container>
                            <Grid item style={{marginTop: '16px', marginRight: 10}}>
                                <TextField
                                    type="text"
                                    step="any"
                                    name="rates"
                                    id="rates"
                                    value={rates}
                                    onChange={handleRates}
                                    placeholder="e.g 10"
                                    label={t('Tax Rates(%)')}

                                />
                            </Grid>
                            <Grid item style={{marginRight: 10}}>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label={t('Due_Date')}
                                        format="dd/MM/yyyy"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </ MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item style={{width: 270, marginRight: 10}}>
                                <Autocomplete
                                    {...defaultProps}
                                    renderInput={(params) => <TextField {...params}
                                                                        label={t('Select_currency')}
                                                                        margin="normal"
                                    />}
                                    value={currency?.value}
                                    onChange={(event, value) => setCurrency(value?.value)}


                                />
                            </Grid>
                        </Grid>

                    </Container>
                </div>
                <div className={styles.note}>
                    <h4>{t('Notes/Terms')}</h4>
                    <textarea
                        placeholder={t('Provide_additional_details_or_terms_of_service')}
                        onChange={(e) => setInvoiceData({...invoiceData, notes: e.target.value})}
                        value={invoiceData?.notes}
                    />
                </div>


                {/* <button className={styles.submitButton} type="submit">Save and continue</button> */}
                <Grid container justifyContent="center">
                    <Button
                        variant="contained"
                        style={{justifyContentContent: 'center'}}
                        type="submit"
                        color="primary"
                        size="large"
                        className={classes.button}
                        startIcon={<SaveIcon/>}
                    >
                        {t('Save_and_Continue')}
                    </Button>
                </Grid>
            </form>
        </div>
    )
}

export default Invoice
