import * as api from '../api/index'
import {
    START_LOADING,
    END_LOADING,
    CREATE_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY,
    FETCH_CATEGORY,
    FETCH_CATEGORIES_BY_USER
} from './constants'


export const getCategoriesByUser = (searchQuery, page) => async (dispatch) => {
    try {
        dispatch({type: START_LOADING})
        const {data: {data, numberOfPages}} = await api.fetchCategoriesByUser(searchQuery, page)
        dispatch({type: FETCH_CATEGORIES_BY_USER, payload: {categories: data, numberOfPages: numberOfPages}});
        dispatch({type: END_LOADING})
    } catch (error) {
        console.log(error.response)
    }
}



export const getCategory = (id) => async (dispatch) => {
    try {
        const {data} = await api.fetchCategory(id)
        dispatch({type: FETCH_CATEGORY, payload: data})
    } catch (error) {
        console.log(error)
    }
}

export const createCategory = (category) => async (dispatch) => {
    try {
        dispatch({type: START_LOADING})
        const {data} = await api.addCategory(category)
        dispatch({type: CREATE_CATEGORY, payload: data})
        dispatch({type: END_LOADING})
    } catch (error) {
        console.log(error)
    }
}

export const updateCategory = (id, category) => async (dispatch) => {
    try {
        const {data} = await api.updateCategory(id, category)
        dispatch({type: UPDATE_CATEGORY, payload: data})
    } catch (error) {
        console.log(error)
    }
}

export const deleteCategory = (id, openSnackbar) => async (dispatch) => {
    try {
        await api.deleteCategory(id)
        dispatch({type: DELETE_CATEGORY, payload: id})
        openSnackbar("Category deleted successfully")
    } catch (error) {
        console.log(error.response)
    }
}


