import Card from "./card";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {getInvoice} from "../../actions/invoiceActions";
import {useDispatch, useSelector} from "react-redux";
import {CalcPriceWithTax, GenerateSaudiTaxCode, toFixedFloat} from "../../utils/utils";
import styles from "./pos.module.css";
import {fetchFile} from "../../actions/upload";
import {getProfilesByUser} from "../../actions/profile";
import {Button} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";


const Receipt = ()=> {
    const {t, i18n} = useTranslation();
    const {id} = useParams();
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const {invoice} =  useSelector((state) => state.invoices)
    const [client, setClient] = useState({});
    const [logoPath, setLogoPath] = useState("");
    const {profiles} = useSelector((state) => state.profiles);
    const [lastInvoiceNumber, setLastInvoiceNumber] = useState();
    const [saudiQrCode, setSaudiQrCode] = useState();
    const [paymentInfo, setPaymentInfo] = useState({});
    const [uniqueCard, setUniqueCard] = useState([]);
    const [paymentData, setPaymentData] = useState([]);

    useEffect(() => {
        dispatch(getInvoice(id));
    }, [id, dispatch, location]);

    useEffect(async () => {
        dispatch(getProfilesByUser({search: invoice?.creator}));
    }, [invoice])

    useEffect(() => {

        if(invoice?._id) {
            console.log(invoice)
            setClient(invoice?.client);
            //setProfiles(invoice?.businessDetails?.data?.data);
            setLastInvoiceNumber(invoice?.invoiceNumber)
            setPaymentInfo({
                total: invoice.subTotal,
                totalDiscount: invoice.discount,
                totalAfterDiscount: invoice.total,
                totalTax: invoice.vat,
                totalPayment:invoice.paymentRecords.reduce((a, b) => toFixedFloat(toFixedFloat(a) + toFixedFloat(b.amountPaid)), 0),
                balance: (invoice.total - invoice.paymentRecords.reduce((a, b) => toFixedFloat(toFixedFloat(a) + toFixedFloat(b.amountPaid)), 0) )
            })
            console.log(invoice.paymentRecords)
            setPaymentData(invoice.paymentRecords.map(method =>{
                return  {paidAmount:method.amountPaid,title:method.paymentMethod}
            }))
            setUniqueCard(invoice.items.map(item => {
                return {shortName: item.itemName, count: item.quantity, total: toFixedFloat(item.unitPrice * item.quantity), priceIncludesTax: true,sellingPrice:item.unitPrice}
            }));
        }
    }, [invoice]);

    useEffect(async () => {
        (profiles?.logo) ? setLogoPath(await dispatch(fetchFile(profiles?.logo))) : setLogoPath("");
        (profiles?.currency === "SAR" && profiles?.businessName) ? setSaudiQrCode(
                GenerateSaudiTaxCode(profiles.businessName, profiles.vatRecord, new Date().toLocaleString(), paymentInfo?.totalAfterDiscount, paymentInfo?.totalTax))
            : setSaudiQrCode(null);
    }, [profiles])

    return (
        <div style={{marginLeft:'auto',marginRight:'auto',width:'450px',background:'#ffffff'}}>
            <Button
                size="large"
                variant="contained"
                color="primary"
                style={{marginLeft:'auto',marginRight:'auto',display:'flex',minWidth:'150px',marginTop:'50px',background: '#35A236FF'}}
                startIcon={<PrintIcon/>}
                onClick={()=> window.print()}
            >Print </Button>
        <Card cardReceipt={styles.cardReceipt} print={true} logoPath={logoPath} client={client}
              profiles={profiles} lastInvoiceNumber={lastInvoiceNumber}
              addToCard={function(){}} removeFromCard={function(){}} saudiQrCode={saudiQrCode}
              paymentInfo={paymentInfo} uniqueCard={uniqueCard} paymentData={paymentData}/>
        </div>
    )

}

export default Receipt