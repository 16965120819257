import {
    START_LOADING,
    END_LOADING
} from '../actions/constants';

const items = (state = {isLoading: false}, action) => {
    switch (action.type) {
        case START_LOADING:
            return {...state, isLoading: true};
        case END_LOADING:
            return {...state, isLoading: false};
        default:
            return state;
    }
};

export default items

