import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import styles from "../Header/Header.module.css";
import i18n from "../../i18n";
import {useTranslation} from "react-i18next";
import {Link, useHistory, useLocation} from 'react-router-dom'
import {Badge, Button, Grid, ListItemIcon, ListItemText, Paper} from "@material-ui/core";
import LanguageIcon from '@material-ui/icons/Language';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Avatar from "@material-ui/core/Avatar";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default function MenuAppBar() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    //const open = Boolean(anchorEl);
    const history = useHistory()
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile'))||null);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const handleChange = (event) => {
        setAuth(event.target.checked);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }
    const openLink = (link) => {
        history.push(`/${link}`)
        setOpen(false);
    }
    const logout = () => {
        dispatch({type: 'LOGOUT'})
        history.push('/')
        setUser(null)
    }
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const lngs = {
        en: {nativeName: 'English'},
        ar: {nativeName: 'عربي'}
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const {t, i18n} = useTranslation();

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const MenuSource = [
        {'title': t('Home'), 'path': "/", 'showInMobileMenu': true, 'showDesktopMenu': true, showBoth: false},
        {'title': t('Prices'), 'path': "/Prices", 'showInMobileMenu': true, 'showDesktopMenu': true, showBoth: false},
        {'title': t('contactus'),'path': "/contactus",'showInMobileMenu': true,'showDesktopMenu': true,showBoth: false},
        {'title': t('About'), 'path': "/about", 'showInMobileMenu': false, 'showDesktopMenu': false, showBoth: false},
        {'title': t('Download'), 'path': "/download", 'showInMobileMenu': false, 'showDesktopMenu': true, showBoth: false},
        {'title': t('Help'), 'path': "/help", 'showInMobileMenu': false, 'showDesktopMenu': false, showBoth: false},
        {'title': t('Get_started'),'path': "/login",'showInMobileMenu': false,'showDesktopMenu': false,showBoth: !user},
        {'title': t('SignUp'), 'path': "/signup", 'showInMobileMenu': !user, 'showDesktopMenu': !user, showBoth: false},
    ]
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {
                MenuSource.filter(link => link.showInMobileMenu === true).map((link) =>
                    (
                        <MenuItem>
                        <Link to={link.path} item
                              className={styles.subMenuLink}>{link.title}
                        </Link>
                        </MenuItem>
                    )
                )
            }
        </Menu>
    );

    return (
        <div className={['np', classes.root].join(' ')}>
            <AppBar position="static">
                <Toolbar>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                    >
                        <MenuItem>
                        <Link to="/">
                            <img style={{width: '100px', height: '50px'}} src="/logo.svg" alt="quantum-invoice"/>
                        </Link>
                        </MenuItem>
                        <div className={classes.sectionDesktop}>
                            {MenuSource.filter(link => link.showDesktopMenu === true).map((link) =>
                                (
                                    <MenuItem>
                                    <Link to={link.path} item
                                          className={styles.menuLink}>{link.title}
                                    </Link>
                                    </MenuItem>
                                )
                            )}
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon/>
                            </IconButton>
                        </div>
                        {MenuSource.filter(link => link.showBoth === true).map((link) =>
                            (
                                <MenuItem>
                                <Link to={link.path} item
                                      className={styles.menuLink}>{link.title}
                                </Link>
                                </MenuItem>
                            )
                        )}

                        <Button
                            variant="contained"
                            color="primary"
                            aria-controls="simple-menu" aria-haspopup="true"
                            onClick={handleClick}
                            startIcon={<LanguageIcon/>}
                        >
                            {t('Language')}
                        </Button>


                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {Object.keys(lngs).map((lng) => (
                                <MenuItem onClick={handleClose}>
                                    <a className={styles.subMenuLink} key={lng} onClick={() => {
                                        i18n.changeLanguage(lng);

                                    }}>
                                        {lngs[lng].nativeName}
                                    </a>
                                </MenuItem>
                            ))}

                        </Menu>
                        {user && (<><Button
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                        >
                            <Avatar style={{backgroundColor: '#1976D2'}}>{user?.result?.name?.charAt(0)}</Avatar>
                        </Button>
                        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                            {({TransitionProps, placement}) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                                >
                                    <Paper elevation={3}>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList autoFocusItem={open} id="menu-list-grow"
                                                      onKeyDown={handleListKeyDown}>
                                                <MenuItem
                                                    onClick={() => openLink('settings')}>{(user?.result?.name)?.split(" ")[0]}</MenuItem>
                                                <MenuItem onClick={() => logout()}>{t('Logout')}</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper></>)}

                    </Grid>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
        </div>
    );
}