import {create} from 'jss';
import rtl from 'jss-rtl';
import React from 'react';
import {StylesProvider, jssPreset, MuiThemeProvider} from '@material-ui/core/styles';
import {createTheme} from "@material-ui/core";

// Configure JSS
const jss = create({plugins: [...jssPreset().plugins, rtl()]});
const theme = createTheme({
    direction: 'rtl',
});

function RTL(props) {
    if (!props.isRTL) return (<>
        {props.children}
    </>);
    return (
        <>
            {(props.isRTL) && <StylesProvider jss={jss}>
                <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
            </StylesProvider>
            }
        </>
    );
}

export default RTL