import {PayPalButton} from "react-paypal-button-v2";
import React from 'react'

const Subscription = (props) => {

    return (
        <PayPalButton
            options={{vault: true}}
            createSubscription={(data, actions) => {
                return actions.subscription.create({
                    plan_id: props.planId //'P-1Y1444755P1762824MHEMJJI'
                });
            }}
            onApprove={(data, actions) => {
                // Capture the funds from the transaction
                return actions.subscription.get().then(function (details) {
                    // Show a success message to your buyer
                    alert("Subscription completed");

                    // OPTIONAL: Call your server to save the subscription
                    return fetch("/paypal-subscription-complete", {
                        method: "post",
                        body: JSON.stringify({
                            orderID: data.orderID,
                            subscriptionID: data.subscriptionID
                        })
                    });
                });
            }}
        />
    );
}

export default Subscription