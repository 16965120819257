import {FETCH_FILE, UPLOAD_FILE, FETCH_FILES_BY_USER, START_LOADING, CREATE_ITEM, END_LOADING} from './constants';
import * as api from '../api/index.js';


export const contactUs = (formData , feedBack) => async (dispatch) => {
    try {
        dispatch({type: START_LOADING})
        await api.contactUs(formData)
        dispatch({type: END_LOADING})
        feedBack("Message Sent")
    } catch (error) {
        console.log(error);
        feedBack(error);
        dispatch({type: END_LOADING})
    }
}