import * as api from '../api/index'
import {
    START_LOADING,
    END_LOADING,
    CREATE_ITEM,
    UPDATE_ITEM,
    DELETE_ITEM,
    FETCH_ITEM,
    FETCH_ITEMS_BY_USER,
    FIND_ITEM_BY_BARCODE,
    ITEM_CATEGORY
} from './constants'
import {readFromCache, writeToCache , readFullItemFromCache , deleteFullItemFromCache} from "../utils/cache";



export const getLastModifiedByUser = (searchQuery) => async (dispatch) => {
    try {
        console.log("&&^&^&%&^^&&^&^^&&^&^&^&^");
        const {data} =  await api.lastModified(searchQuery);
        console.log("&&^&^&%&^^&&^&^^&&^&^&^&^1");
        const lastModified = new Date((await readFromCache(`lastModified`))?.toString() ).getTime();
        const lastModifiedOnline = new Date(data?.data[0]?.lastModified || new Date(new Date().getTime() - 365*24*60*60*1000).toLocaleString()).getTime();
        console.log("&&^&^&%&^^&&^&^^&&^&^&^&^2");
console.log(await readFromCache(`lastModified`));
        console.log(`${lastModifiedOnline} > ${lastModified})`)
        console.log(await readFromCache(`lastModified`))
        if(lastModifiedOnline > lastModified || isNaN(lastModified) ){
            await deleteFullItemFromCache('items_');
            await writeToCache(`lastModified`, data?.data[0]?.lastModified||null);
        }

    } catch (error) {
        console.log(error)
        console.log(error.response)
    }
}

export const getItemsByUser = (searchQuery, page , pageSize = 15 , category='' ) => async (dispatch) => {
    try {
        dispatch({type: START_LOADING})
        const key = `${searchQuery.search}_${page}_${pageSize}_${category}`;
        const {data: {data, numberOfPages}} = await readFromCache(`items_${key}`) || await api.fetchItemsByUser(searchQuery, page , pageSize ,category )
        dispatch({type: FETCH_ITEMS_BY_USER, payload: {items: data, numberOfPages: numberOfPages}});
        dispatch({type: END_LOADING})
        await writeToCache(`items_${key}`, {data: {data: data, numberOfPages: numberOfPages}})
    } catch (error) {
        console.log(error.response)
    }
}

export const findItemByBarcode = (barcode ) => async (dispatch) => {
    try {
        const data  =  Object.values(await readFullItemFromCache('items_'))
            .flatMap(d => d.data).flatMap(d =>d.data)
            .filter(item => item._id === barcode || item.barcode == barcode
                || item.description.includes(barcode) || item.shortName.includes(barcode));
        const items = data.filter((v,i,a)=>a.findIndex(t=>(t._id===v._id))===i);
        dispatch({type: FIND_ITEM_BY_BARCODE, payload: (items.length>0)?items:null});
    } catch (error) {
        console.log(error.response)
    }
}


export const getItem = (id) => async (dispatch) => {
    try {
        const {data} = await api.fetchItem(id)
        dispatch({type: FETCH_ITEM, payload: data})
    } catch (error) {
        console.log(error)
    }
}

export const createItem = (item) => async (dispatch) => {
    try {
        dispatch({type: START_LOADING})
        const {data} = await api.addItem(item)
        dispatch({type: CREATE_ITEM, payload: data})
        //history.push(`/item/${data._id}`)
        dispatch({type: END_LOADING})
    } catch (error) {
        console.log(error)
    }
}

export const SetCategory = (category) => async (dispatch) => {
    try {
        dispatch({type: ITEM_CATEGORY, payload: category})
    } catch (error) {
        console.log(error)
    }
}


export const updateItem = (id, item) => async (dispatch) => {
    try {
        const {data} = await api.updateItem(id, item)
        dispatch({type: UPDATE_ITEM, payload: data});
        await writeToCache(`lastModified`, new Date(new Date().getTime() - 365*24*60*60*1000).toLocaleString());
    } catch (error) {
        console.log(error)
    }
}

export const deleteItem = (id, openSnackbar) => async (dispatch) => {
    try {
        await api.deleteItem(id)
        dispatch({type: DELETE_ITEM, payload: id})
        openSnackbar("Item deleted successfully");
        await writeToCache(`lastModified`, new Date(new Date().getTime() - 365*24*60*60*1000).toLocaleString());
    } catch (error) {
        console.log(error.response)
    }
}


