import {FETCH_FILE, UPLOAD_FILE, FETCH_FILES_BY_USER, START_LOADING, CREATE_ITEM, END_LOADING} from './constants';
import * as api from '../api/index.js';
import {fetchFilesByUser} from "../api/index.js";
import {readFromCache, writeToCache} from "../utils/cache";


export const uploadFile = (form) => async (dispatch) => {
    try {
        // dispatch({ type: START_LOADING })
        const {data} = await api.uploadFile(form);
        // history.push(`/profiles/${data._id}`)
        dispatch({type: UPLOAD_FILE, payload: data});
        return data;
        // dispatch({ type: END_LOADING })
    } catch (error) {
        console.log(error);
    }
};


export const fetchFile = (id) => async (dispatch) => {
    try {
        const {data} = await readFromCache(id) || await api.fetchFileById(id);
        dispatch({type: FETCH_FILE, payload: id});
        await writeToCache(id, {data})
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const fetchFiles = (searchQuery) => async (dispatch) => {
    try {
        const {data} = await readFromCache(searchQuery) || await api.fetchFilesByUser(searchQuery);
        dispatch({type: FETCH_FILES_BY_USER, payload: data});
        await writeToCache(searchQuery, {data: data})
    } catch (error) {
        console.log(error);
    }
};


