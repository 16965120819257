import {
    UPLOAD_FILE,
    START_LOADING,
    END_LOADING, FETCH_FILE, FETCH_FILES_BY_USER
} from '../actions/constants';

const uploadReducer = (state = {isLoading: true, upload: [],files:[]}, action) => {
    switch (action.type) {
        case START_LOADING:
            return {...state, isLoading: true}
        case END_LOADING:
            return {...state, isLoading: false}
        case UPLOAD_FILE:
            return {...state, upload: [...state.upload, action.payload]}
        case FETCH_FILE:
            return {...state, upload: action.payload }
        case FETCH_FILES_BY_USER:
            return {...state, files: action.payload }
        default:
            return state;
    }
};

export default uploadReducer


