// src/utils/cache.js
const readFullItemFromCache = async (dataPrefix) => {
    const data = Object.keys(localStorage).filter(key => key.startsWith(dataPrefix))
        .reduce((obj, k) => {
            return {...obj, [k]: JSON.parse(localStorage.getItem(k))}
        }, {});
    return data;
}

const deleteFullItemFromCache = async (dataPrefix) => {
     Object.keys(localStorage).filter(key => key.startsWith(dataPrefix)).forEach((key)=>{localStorage.removeItem(key);
         console.log("&*^&*^&*^&*^&*^&*^&*_____________");
         console.log(key);
     });
    console.log("&*^&*^&*^&*^&*^&*^&*")
     console.log(dataPrefix);
}

const writeToCache = async (uniqKey, data) => localStorage.setItem(uniqKey, JSON.stringify(data));
const readFromCache = async (uniqKey) => JSON.parse(localStorage.getItem(uniqKey)) || null;

export {readFromCache, writeToCache, readFullItemFromCache,deleteFullItemFromCache};


