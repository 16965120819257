export const featuresar = [
    ['الخاصية',
        'مجاني',
        'فضي',
        'ذهبي'
    ],

    ['السعر',
        'مجانا',
        '$5/شهريا',
        '$10/شهريا'
    ],
    ['إنشاء الفواتير ',
        '✔',
        '✔',
        '✔'
    ],

    ['انشاء السندات',
        '✔',
        '✔',
        '✔'
    ],

    ['انشاء عروض الاسعار',
        '✔',
        '✔',
        '✔'
    ],

    ['انشاء التقديرات ',
        '✔',
        '✔',
        '✔'
    ],
    ['انشاء الاشعارات ',
        '✔',
        '✔',
        '✔'
    ],

    ['اضافة شعار شركتك ',
        '✔',
        '✔',
        '✔'
    ],

    ['اضافة بيانات شركتك ',
        '✔',
        '✔',
        '✔'
    ],

    ['انشاء رمز استجابة باركود ',
        '✔',
        '✔',
        '✔'
    ],

    ['تسجيل الدفعات ',
        '✔',
        '✔',
        '✔'
    ],

    ['دخول GOOGLE',
        '✔',
        '✔',
        '✔'
    ],

    ['الدخول العادي',
        '✔',
        '✔',
        '✔'
    ],

    ['تعدد العملات',
        '✔',
        '✔',
        '✔'
    ],

    ['تسجيل القيمة المضافة',
        '✔',
        '✔',
        '✔'
    ],

    ['قائمة العملاء',
        '✔',
        '✔',
        '✔'
    ],

    ['قائمة الفواتير',
        '✔',
        '✔',
        '✔'
    ],

    ['حذف الفواتير ',
        '✔',
        '✔',
        '✔'
    ],

    ['تطبيق الخصومات علي  المنتجات',
        '✔',
        '✔',
        '✔'
    ],

    ['نسخة كفية',
        '✔',
        '✔',
        '✔'
    ],

    ['متوافق مع اللمس',
        '✔',
        '✔',
        '✔'
    ],

    ['شاشة تقارير',
        '✔',
        '✔',
        '✔'
    ],

    ['تصدير PDF ',
        '✔',
        '✔',
        '✔'
    ],

    ['Free SSL',
        '✔',
        '✔',
        '✔'
    ],
    ['ارسال بريد',
        '✔',
        '✔',
        '✔'
    ],

    ['Invoice per day ',
        '20',
        '100',
        'Unlimited'
    ],

    ['بريد / يوم ',
        '20',
        '100',
        'Unlimited'
    ],

    ['احتفاظ بالفواتير ',
        '11 شهر',
        '2 سنة',
        'غير محدود'
    ],

    ['الاحتفاظ بيانات العملاء  ',
        '11 شهر',
        '2 سنة',
        'غير محدود'
    ],

    ['الاكمال التلقائي لبيانات المنتجات ',
        '10',
        '30',
        'غير محدود'
    ],

    ['فواتير دورية',
        '❌',
        '❌',
        '✔'
    ],

    ['دعم عبر الهاتف',
        '❌',
        '❌',
        '✔'
    ],

    ['دعم فني',
        'Website help center',
        'chat',
        'chat and phone'
    ],

    ['أولوية الدعم',
        '❌',
        '❌',
        '✔'
    ],
    ['تنبية في حالات الصيانة الدورية',
        '❌',
        '✔',
        '✔'
    ],
    ['السعر',
        'مجانا',
        '$5/شهريا',
        '$10/شهريا'
    ],

];