import React from 'react'
import styles from './Settings.module.css'
import Form from './Form/Form'
import {useHistory} from 'react-router-dom'
import {useTranslation, Trans} from 'react-i18next';

const Settings = () => {

    const history = useHistory()
    const user = JSON.parse(localStorage.getItem('profile'))
    const {t, i18n} = useTranslation();

    if (!user) {
        history.push('/login')
    }


    return (
        <div className={styles.pageContainer}>

            <section className={styles.hero}>
                <h1>{t('Profile_Settings')}</h1>
                <div className={styles.paragraph}>
                    <p>{t('Edit_update_your_business_profile')}</p>
                </div>
            </section>
            <section className={styles.stat}>

                <Form user={user}/>
            </section>

        </div>
    )
}

export default Settings
