import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import styles from './Items.module.css'
// import moment from 'moment'
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Container from '@material-ui/core/Container'
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {Button} from '@material-ui/core';
import {useSnackbar} from 'react-simple-snackbar'

import {deleteItem, getItemsByUser, getLastModifiedByUser} from '../../actions/itemActions';
import {useTranslation, Trans} from 'react-i18next';
import {Pagination} from "@material-ui/lab";
import AddItem from "./AddItem";
import {useHistory, useLocation} from "react-router-dom";
import {getClientsByUser} from "../../actions/clientActions";
import items from "../../reducers/items";


// import items from '../../items.json'

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),

    },
}));


const useStyles2 = makeStyles(theme => ({
    table: {
        minWidth: 500,
    },

    tablecell: {
        fontSize: '16px'
    }
}));


const Items = () => {

    const history = useHistory()
    const location = useLocation()
    const [currentId, setCurrentId] = useState(null)
    const user = JSON.parse(localStorage.getItem('profile'))
    const {items, isLoading, numberOfPages} = useSelector((state) => state.items)
    const [currentPage, setCurrentPage] = useState(1)
    const classes = useStyles2();
    // eslint-disable-next-line
    const [openSnackbar, closeSnackbar] = useSnackbar()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)

    if (!user) {
        history.push('/login')
    }

    useEffect(() => {
        dispatch(getItemsByUser({search: user?.result._id || user?.result?.googleId}, currentPage));
        console.log(items)
    }, [location, dispatch, currentPage])

    const handleEdit = (selectedItem) => {
        setOpen((prevState) => !prevState)
        setCurrentId(selectedItem)
    }

    const openAddNew = () => {
        setOpen(true)
        setCurrentId(null)
    }
    const handleChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    }
    const tableStyle = {
        width: 160,
        fontSize: 14,
        cursor: 'pointer',
        borderBottom: 'none',
        padding: '8px',
        textAlign: 'center'
    }
    const headerStyle = {borderBottom: 'none', textAlign: 'center'}
    const {t, i18n} = useTranslation();
    useEffect(async () => {
        dispatch(getLastModifiedByUser({search: user?.result._id || user?.result?.googleId}));
    }, []);

    return (
        <div className={styles.pageLayout}>


            <Container>
                <div>


                    <AddItem currentId={currentId} setCurrentId={setCurrentId} setOpen={setOpen}
                                 open={open}></AddItem>
                    <Button onClick={openAddNew} variant="outlined"
                            style={{marginBottom:'10px'}}
                            startIcon={<AddCircleIcon style={{width: '24px', height: '24px', color: "green"}}/>}>
                        &nbsp; New Item
                    </Button>
                </div>
                <TableContainer component={Paper} elevation={0}>
                    <Table className={classes.table} aria-label="custom pagination table">

                        <TableHead>
                            <TableRow>
                                <TableCell style={{...headerStyle, width: '10px'}}>{t('Number')}</TableCell>
                                <TableCell style={headerStyle}>{t('Name')}</TableCell>
                                <TableCell style={headerStyle}>{t('Price')}</TableCell>
                                <TableCell style={headerStyle}>{t('Edit')}</TableCell>
                                <TableCell style={headerStyle}>{t('Delete')}</TableCell>

                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {items.map((row, index) => (
                                <TableRow key={row._id} styel={{cursor: 'pointer'}}>
                                    <TableCell style={{...tableStyle, width: '10px'}}>{index + 1}</TableCell>
                                    <TableCell style={tableStyle} scope="row"> <Button
                                        style={{textTransform: 'none'}}> {row.shortName} </Button></TableCell>
                                    <TableCell style={tableStyle} scope="row"> {row.sellingPrice}</TableCell>
                                    <TableCell style={{...tableStyle, width: '10px'}}>
                                        <IconButton onClick={() => handleEdit(row._id)}>
                                            <BorderColorIcon style={{width: '20px', height: '20px'}}/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell style={{...tableStyle, width: '10px'}}>
                                        <IconButton onClick={() => dispatch(deleteItem(row._id, openSnackbar))}>
                                            <DeleteOutlineRoundedIcon style={{width: '20px', height: '20px'}}/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <Pagination count={numberOfPages} page={currentPage} color="secondary"
                                                onChange={handleChange} variant="outlined" showFirstButton
                                                showLastButton/>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Container>
        </div>
    );
}

export default Items