import React, {useEffect, useState} from 'react';
import {findItemByBarcode, SetCategory} from "../../actions/itemActions";
import {useDispatch, useSelector} from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import {FormHelperText, InputLabel, MenuItem, OutlinedInput, Select} from "@material-ui/core";
import {getCategoriesByUser} from "../../actions/categoryActions";
import {useLocation} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const PosHeader = () => {
    const dispatch = useDispatch()
    const {categories} = useSelector((state) => state.categories)
    const {category} = useSelector((state) => state.items)
    const user = JSON.parse(localStorage.getItem('profile'))
    const location = useLocation();
    const classes = useStyles();
    useEffect(() => {
        dispatch(getCategoriesByUser({search: user?.result._id || user?.result?.googleId}, 0));
    }, [location, dispatch])


    const handleChange = (event) => {
        dispatch(SetCategory(event.target.value))
    };

    return (
        <>
            <FormControl style={{marginLeft: '60px', marginRight: '60px'}}>
                <OutlinedInput placeholder="ٍSearch or Barcode" onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        dispatch(findItemByBarcode(e.target.value))
                        e.target.value = '';
                    }
                }}/>
            </FormControl>
            <FormControl variant="outlined" style={{minWidth:'170px'}}>
                <InputLabel id="demo-simple-select-outlined-label">Select Category</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={category}
                    onChange={handleChange}
                    label="Select Category"
                >
                    <MenuItem value="">
                        <em>All </em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    {categories.map(cat => (
                        <MenuItem value={cat._id}>{cat.category}</MenuItem>
                    ))}
                </Select>
            </FormControl>


        </>

    );
}

export default PosHeader