import {combineReducers} from 'redux'

import invoices from './invoices'
import clients from './clients'
import auth from './auth'
import profiles from './profiles'
import upload from './upload'
import categories from './categories'
import items from './items'
import contactus from './contactus'
export default combineReducers({invoices, clients, auth, profiles, upload, categories, items,contactus})