/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux'
import {useSnackbar} from 'react-simple-snackbar'
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Avatar, Button, Paper, Grid, Container} from '@material-ui/core';
import Uploader from './Uploader';
import {getProfilesByUser, updateProfile} from '../../../actions/profile';
import useStyles from './styles';
import Input from './Input';
import {useTranslation, Trans} from 'react-i18next';
import {fetchFile} from "../../../actions/upload";
import {getClientsByUser} from "../../../actions/clientActions";
import Spinner from "../../Spinner/Spinner";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import currencies from '../../../currencies.json'

const Form = () => {
    const {t, i18n} = useTranslation();
    const user = JSON.parse(localStorage.getItem('profile'))
    const initialState = {
        name: '',
        email: '',
        phoneNumber: '',
        businessName: '',
        contactAddress: '',
        logo: '',
        commercialRecord: '',
        vatRecord: '',
        vatPercentage: '',
        currency: ''
    };

    const [form, setForm] = useState(initialState);
    const [logoPath, setLogoPath] = useState();
    const location = useLocation()
    const dispatch = useDispatch();
    const classes = useStyles();
    const {profiles} = useSelector((state) => state.profiles)
    const isLoading = useSelector(state => state.profiles.isLoading)
    const [switchEdit, setSwitchEdit] = useState(0)

    // eslint-disable-next-line
    const [openSnackbar, closeSnackbar] = useSnackbar()


    useEffect(async () => {
        if (profiles?.logo || form?.logo) {
            const res = await dispatch(fetchFile(form?.logo || profiles?.logo));
            setLogoPath(res);
        }
    }, [form?.logo, location, switchEdit, profiles])

    useEffect(() => {
        if (switchEdit === 1) {
            setForm(profiles)
        }
    }, [switchEdit])

    useEffect(() => {
        if (user?.result?._id || user?.result?.googleId) {
            dispatch(getProfilesByUser({search: (user?.result?._id || user?.result?.googleId)}))
        }
    }, [location, switchEdit])

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateProfile(profiles?._id, form, openSnackbar));
        setSwitchEdit(0)

    };

    const handleChange = (e) => setForm({...form, [e.target.name]: e.target.value});
    const setUploadedFile = (file) => setForm({...form, logo: file});

    if (isLoading) {
        return <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingTop: '20px'
        }}>
            <Spinner/>
        </div>
    }

    const defaultProps = {
        options: currencies,
        getOptionLabel: (option) => option.label
    };
    const flatProps = {
        options: currencies.map((option) => option.value),
    };
    return (
        <div>
            {switchEdit === 0 && (
                <Container component="main" maxWidth="sm">
                    <Paper className={classes.paper} elevation={2}>
                        <Avatar style={{width: '100px', height: '100px', margin: '30px'}} src={logoPath} alt=""
                                className={classes.avatar}>
                        </Avatar>
                        <p>{profiles?.businessName}</p>
                        <p>{profiles?.contactAddress}</p>
                        <p>{profiles?.phoneNumber}</p>
                        <p>{profiles?.email}</p>
                        <p>{profiles?.commercialRecord}</p>
                        <p>{profiles?.vatRecord}</p>
                        <p>{profiles?.vatPercentage}</p>
                        <p>{profiles?.currency}</p>
                        <Button variant="outlined" style={{margin: '30px', padding: '15px 30px'}}
                                onClick={() => setSwitchEdit(1)}>{t('Edit_Profile')}</Button>
                    </Paper>
                </Container>
            )}

            {switchEdit === 1 && (
                <Container component="main" maxWidth="sm">
                    <Paper className={classes.paper} elevation={1}>
                        <Avatar style={{width: '100px', height: '100px'}} src={logoPath} alt=""
                                className={classes.avatar}>
                        </Avatar>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Uploader setUploadedFile={setUploadedFile}/>
                                <Input name="email" label={t('Email_Address')} handleChange={handleChange} type="email"
                                       half
                                       value={form?.email}/>
                                <Input name="phoneNumber" label={t('Phone_Number')} handleChange={handleChange}
                                       type="text"
                                       half value={form?.phoneNumber}/>
                                <Input name="businessName" label={t('Business_Name')} handleChange={handleChange}
                                       type="text"
                                       value={form?.businessName}/>
                                <Input name="contactAddress" label={t('Contact_Address')} handleChange={handleChange}
                                       type="text" value={form?.contactAddress}/>
                                <Input name="commercialRecord" label={t('COMMERCIAL_RECORD')}
                                       handleChange={handleChange}
                                       type="text" value={form?.commercialRecord}/>
                                <Input name="vatRecord" label={t('VAT_RECORD')} handleChange={handleChange}
                                       type="text" value={form?.vatRecord}/>
                                <Input name="vatPercentage" label={t('VAT_Percentage%')} handleChange={handleChange}
                                       type="text" value={form?.vatPercentage}/>
                                <Grid item xs={12} sm={12}>
                                <Autocomplete
                                    id="currency"
                                    name="currency"
                                    {...flatProps}
                                    style={{width: '100%'}}
                                    value={form?.currency}
                                    onChange={(event, value) => {
                                        setForm({...form, currency: value});
                                        console.log(value)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="currency"
                                                                        variant="outlined"/>}
                                />
                                </Grid>

                            </Grid>
                            <Button type="submit" fullWidth variant="contained" color="primary"
                                    className={classes.submit}>
                                {t('Update_Settings')}
                            </Button>
                            <Grid container justifyContent="flex-end">
                            </Grid>
                        </form>
                    </Paper>
                </Container>
            )}
        </div>
    );
};

export default Form;
