import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom'
import {Button, Paper, Typography, Container, Grid} from '@material-ui/core';
import useStyles from './styles';
import Field from '../Login/Field';
import {forgot} from '../../actions/auth';
import styles from './Password.module.css'
import {useTranslation, Trans} from 'react-i18next';

const Forgot = () => {
    const classes = useStyles();
    const history = useHistory()
    const [form, setForm] = useState("");
    const [step, setStep] = useState(0)
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('profile'))
    const {t, i18n} = useTranslation();
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(forgot({email: form}))
        window.navigator.onLine ? setStep(1) : setStep(2)
    }

    const handleChange = (e) => setForm(e.target.value);

    if (user) history.push('/dashboard')

    return (
        <div style={{paddingTop: '100px', paddingBottom: '100px'}}>
            <Container component="main" maxWidth="xs">
                <Paper className={classes.paper} variant="outlined">
                    {step === 0 && (
                        <div>
                            <Typography variant="h6" gutter="5">Please enter your email address</Typography>
                            <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Field name="email" label={t("Email Address")} handleChange={handleChange} type="email"/>
                                    <Button type="submit" fullWidth variant="contained" color="primary"
                                            className={classes.submit}> {t("Submit")} </Button>
                                </Grid>
                            </form>
                        </div>
                    )}

                    {step === 1 && (
                        <div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><i
                                className="fas fa-check-circle" style={{fontSize: '55px', color: '#3e6947'}}></i></div>
                            <br/>
                            <p>{t('A_password_reset_link_has_been_sent_to_your_email._Please_follow_the_link_to_reset_your_password')}</p>
                            <div className={styles.buttons}>
                                <button className={styles.button} onClick={() => history.push('/')}>Back to home
                                </button>
                                <button className={styles.button} onClick={() => setStep(0)}>{t('Resend_link')}</button>
                            </div>
                        </div>
                    )}

                    {step === 2 && (
                        <div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><i
                                className="fas fa-check-circle" style={{fontSize: '55px', color: '#3e6947'}}></i></div>
                            <br/>
                            <p>Please check your internet connection and try again</p>
                            <div className={styles.buttons}>
                                <button className={styles.button} onClick={() => history.push('/')}>{t('Back_to_home')}
                                </button>
                                <button className={styles.button} onClick={() => setStep(0)}>{t('Resend_link')}</button>
                            </div>
                        </div>
                    )}
                </Paper>
            </Container>
        </div>

    );
}

export default Forgot
