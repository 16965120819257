import React from 'react'
import moment from 'moment'
import {toCommas} from '../../utils/utils'
import styles from './InvoiceDetails.module.css'
import {useTranslation, Trans} from 'react-i18next';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const PaymentHistory = ({paymentRecords}) => {
    const {t, i18n} = useTranslation();

    return (
        <div className="tabs paymentHistory">
            <div className="tab">
                <input type="checkbox" id="chck1"/>
                <label className="tab-label" htmlFor="chck1">Payment History <span
                    className={styles.totalUnpaid}>{paymentRecords?.length}</span><span className={styles.space}></span></label>
                <div className="tab-content">
                    <div>
                        <table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('Date_Paid')}</TableCell>
                                    <TableCell>{t('Amount_Paid')}</TableCell>
                                    <TableCell>{t('Payment_Method')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <tbody>
                            {paymentRecords?.map((record) => (
                                <tr key={record._id}>
                                    <td>{moment(record.datePaid).format('MMMM Do YYYY')}</td>
                                    <td>{toCommas(record.amountPaid)}</td>
                                    <td>{t(record.paymentMethod)}</td>
                                </tr>

                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PaymentHistory
