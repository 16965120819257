import React, {useState} from 'react';
import {useDispatch} from 'react-redux'
import styles from './Clients.module.css'
// import moment from 'moment'
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Container from '@material-ui/core/Container'
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {Button} from '@material-ui/core';
import {useSnackbar} from 'react-simple-snackbar'

import {deleteClient} from '../../actions/clientActions';
import {useTranslation, Trans} from 'react-i18next';
import {Pagination} from "@material-ui/lab";


// import clients from '../../clients.json'

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),

    },
}));




const useStyles2 = makeStyles(theme => ({
    table: {
        minWidth: 500,
    },

    tablecell: {
        fontSize: '16px'
    }
}));


const Clients = ({setOpen, setCurrentId, clients, pages}) => {

    const classes = useStyles2();
    // eslint-disable-next-line
    const [openSnackbar, closeSnackbar] = useSnackbar()
    const dispatch = useDispatch()


    const handleEdit = (selectedInvoice) => {

        setOpen((prevState) => !prevState)
        setCurrentId(selectedInvoice)

    }


    const tableStyle = {
        width: 160,
        fontSize: 14,
        cursor: 'pointer',
        borderBottom: 'none',
        padding: '8px',
        textAlign: 'center'
    }
    const headerStyle = {borderBottom: 'none', textAlign: 'center'}
    const {t, i18n} = useTranslation();

    return (
        <div className={styles.pageLayout}>
            <Container >
                <TableContainer component={Paper} elevation={0}>
                    <Table className={classes.table} aria-label="custom pagination table">

                        <TableHead>
                            <TableRow>
                                <TableCell style={{...headerStyle, width: '10px'}}>{t('Number')}</TableCell>
                                <TableCell style={headerStyle}>{t('Name')}</TableCell>
                                <TableCell style={headerStyle}>{t('Email')}</TableCell>
                                <TableCell style={headerStyle}>{t('Phone')}</TableCell>
                                <TableCell style={headerStyle}>{t('Edit')}</TableCell>
                                <TableCell style={headerStyle}>{t('Delete')}</TableCell>

                            </TableRow>
                        </TableHead>

                        <TableBody>
                            { clients.map((row, index) => (
                                <TableRow key={row._id} styel={{cursor: 'pointer'}}>
                                    <TableCell style={{...tableStyle, width: '10px'}}>{index + 1}</TableCell>
                                    <TableCell style={tableStyle} scope="row"> <Button
                                        style={{textTransform: 'none'}}> {row.name} </Button></TableCell>
                                    <TableCell style={tableStyle}>{row.email}</TableCell>
                                    <TableCell style={tableStyle}>{row.phone}</TableCell>
                                    <TableCell style={{...tableStyle, width: '10px'}}>
                                        <IconButton onClick={() => handleEdit(row._id)}>
                                            <BorderColorIcon style={{width: '20px', height: '20px'}}/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell style={{...tableStyle, width: '10px'}}>
                                        <IconButton onClick={() => dispatch(deleteClient(row._id, openSnackbar))}>
                                            <DeleteOutlineRoundedIcon style={{width: '20px', height: '20px'}}/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <Pagination count={pages.count} page={pages.page} color="secondary"
                                                onChange={pages.handleChange} variant="outlined" showFirstButton
                                                showLastButton/>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Container>
        </div>
    );
}

export default Clients