import React, {useState, useEffect} from 'react'
// import "../../../node_modules/react-progress-button/react-progress-button.css"
import {useSnackbar} from 'react-simple-snackbar'
import {useLocation, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {initialState} from '../../initialState'
import {getInvoice} from '../../actions/invoiceActions'
import {GenerateSaudiTaxCode, toCommas, toFixedFloat} from '../../utils/utils'
import styles from './InvoiceDetails.module.css'
import moment from 'moment'
import {useHistory} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import {Container, Grid} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PrintIcon from '@material-ui/icons/Print';
import Spinner from '../Spinner/Spinner'

import ProgressButton from 'react-progress-button'
import axios from 'axios';
import {saveAs} from 'file-saver';
import Modal from '../Payments/Modal'
import PaymentHistory from './PaymentHistory'

import QRCode from 'qrcode.react';
import {useTranslation, Trans} from 'react-i18next';
import {fetchFile} from "../../actions/upload";
import {getProfile, getProfilesByUser} from "../../actions/profile";

const InvoiceDetails = () => {
    const {t, i18n} = useTranslation();
    const location = useLocation()
    const [invoiceData, setInvoiceData] = useState(initialState)
    const [rates, setRates] = useState(0)
    const [vat, setVat] = useState(0)
    const [currency, setCurrency] = useState('')
    const [subTotal, setSubTotal] = useState(0)
    const [total, setTotal] = useState(0)
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [client, setClient] = useState([])
    const [type, setType] = React.useState('')
    const [status, setStatus] = useState('')
    const [company, setCompany] = useState({})
    const [profileData, setProfileData] = useState({});
    const {id} = useParams()
    const {invoice} = useSelector((state) => state.invoices)
    const dispatch = useDispatch()
    const history = useHistory()
    const [sendStatus, setSendStatus] = useState(null)
    const [downloadStatus, setDownloadStatus] = useState(null)
    // eslint-disable-next-line
    const [openSnackbar, closeSnackbar] = useSnackbar()
    const user = JSON.parse(localStorage.getItem('profile'))
    const {profiles} = useSelector((state) => state.profiles)
    const [logoPath, setLogoPath] = useState("");
    const [saudiQrCode, setSaudiQrCode] = useState("");

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        large: {
            width: theme.spacing(12),
            height: theme.spacing(12),
        },
        table: {
            minWidth: 650,
        },

        headerContainer: {
            // display: 'flex'
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(1),
            backgroundColor: '#ffffff',
            borderRadius: '10px 10px 0px 0px'
        }
    }));


    const classes = useStyles()
    /*useEffect(async () => {
        if (!invoice?.creator) return
        const profile = await dispatch(getProfilesByUser({search: invoice.creator[0]}));
        //setProfileData(profile);
        const res = await dispatch(fetchFile(profile?.logo));
        setLogoPath(res);

    }, [invoice])*/

    useEffect(async () => {
        (invoice?.currency === "SAR" && profileData?.businessName) ? setSaudiQrCode(
                GenerateSaudiTaxCode(profileData.businessName, profileData.vatRecord, invoice?.dueDate, invoice?.total, invoice?.vat))
            : setSaudiQrCode("null");
        dispatch(getProfilesByUser({search: invoice?.creator}));
        //(profileData?.logo) ? setLogoPath(await dispatch(fetchFile(profileData?.logo))) : setLogoPath("");

    }, [profileData, invoice])

    useEffect(async () => {
        (profiles?.logo) ? setLogoPath(await dispatch(fetchFile(profiles?.logo))) : setLogoPath("");
        setProfileData(profiles);
    }, [profiles])


    useEffect(() => {
        dispatch(getInvoice(id));

    }, [id, dispatch, location]);

    useEffect(() => {
        if (invoice?.creator) {
            //Automatically set the default invoice values as the ones in the invoice to be updated
            setInvoiceData(invoice)
            setRates(invoice.rates)
            setClient(invoice.client)
            setType(invoice.type)
            setStatus(invoice.status)
            setSelectedDate(invoice.dueDate)
            setVat(invoice.vat)
            setCurrency(invoice.currency)
            setSubTotal(invoice.subTotal)
            setTotal(invoice.total)
            setCompany(invoice?.businessDetails?.data?.data)
            //setProfileData(invoice?.businessDetails?.data?.data);


        }
    }, [invoice])

    //Get the total amount paid
    let totalAmountReceived = 0
    for (var i = 0; i < invoice?.paymentRecords?.length; i++) {
        totalAmountReceived += Number(invoice?.paymentRecords[i]?.amountPaid)
    }


    const editInvoice = (id) => {
        history.push(`/edit/invoice/${id}`)
    }

    const createAndDownloadPdf = () => {
        setDownloadStatus('loading')
        axios.post(`${process.env.REACT_APP_API}/create-pdf`,
            {
                html: "" + document.getElementsByTagName("html")[0].innerHTML,
                origin: "" + window.location.origin,
                location: "" + window.location.href,
                name: invoice.client.name,
                logo: logoPath,
                address: invoice.client.address,
                phone: invoice.client.phone,
                email: invoice.client.email,
                dueDate: invoice.dueDate,
                date: invoice.createdAt,
                id: invoice.invoiceNumber,
                notes: "" + window.location.href,
                subTotal: toCommas(invoice.subTotal),
                total: toCommas(invoice.total),
                type: invoice.type,
                vat: invoice.vat,
                items: invoice.items,
                status: invoice.status,
                totalAmountReceived: toCommas(totalAmountReceived),
                balanceDue: toCommas(total - totalAmountReceived),
                company: company,
            })
            .then((res) => {
                axios.get(`${process.env.REACT_APP_API}/fetch-pdf/?filename=${res.data.filename}`, {responseType: 'blob'}).then(r => {
                    const pdfBlob = new Blob([r.data], {type: 'application/pdf'});

                    saveAs(pdfBlob, 'invoice.pdf')
                })
                console.log(res)
            })
            .then(() => setDownloadStatus('success'))
    }


    //SEND PDF INVOICE VIA EMAIL
    const sendPdf = (e) => {
        e.preventDefault()
        setSendStatus('loading')
        axios.post(`${process.env.REACT_APP_API}/send-pdf`,
            {
                html: "" + document.getElementsByTagName("html")[0].innerHTML,
                origin: "" + window.location.origin,
                location: "" + window.location.href,
                name: invoice.client.name,
                logo: logoPath,
                address: invoice.client.address,
                phone: invoice.client.phone,
                email: invoice.client.email,
                dueDate: invoice.dueDate,
                date: invoice.createdAt,
                id: invoice.invoiceNumber,
                notes: invoice.notes,
                subTotal: toCommas(invoice.subTotal),
                total: toCommas(invoice.total),
                type: invoice.type,
                vat: invoice.vat,
                items: invoice.items,
                status: invoice.status,
                totalAmountReceived: toCommas(totalAmountReceived),
                balanceDue: toCommas(total - totalAmountReceived),
                link: `${process.env.REACT_APP_URL}/invoice/${invoice._id}`,
                company: company,
            })
            // .then(() => console.log("invoice sent successfully"))
            .then(() => setSendStatus('success'))
            .catch((error) => {
                console.log(error)
                setSendStatus('error')
            })
    }


    const iconSize = {height: '18px', width: '18px', marginRight: '10px', color: 'gray'}
    const [open, setOpen] = useState(false)


    function checkStatus() {
        return totalAmountReceived >= total ? "green"
            : status === "Partial" ? "#1976d2"
                : status === "Paid" ? "green"
                    : status === "Unpaid" ? "red"
                        : "red";
    }


    if (!invoice) {
        return (
            <Spinner/>
        )
    }


    return (
        <div className="Main-section">
            {invoice?.creator?.includes(user?.result?._id || user?.result?.googleId) && (
                <div className={' actionBtns ' + styles.buttons}>
                    <ProgressButton
                        onClick={sendPdf}
                        state={sendStatus}
                        onSuccess={() => openSnackbar("Invoice sent successfully")}
                    >
                        {t('Send_to_Customer')}
                    </ProgressButton>

                    <ProgressButton
                        onClick={createAndDownloadPdf}
                        state={downloadStatus}>
                        {t('Download_PDF')}
                    </ProgressButton>
                    <button
                        className={styles.btn}
                        onClick={() => window.print()}
                    >
                        <PrintIcon style={iconSize}
                        />
                        {t('Print')}
                    </button>
                    <button
                        className={styles.btn}
                        onClick={() => history.push("/pos/" + id)}
                    >
                        <PrintIcon style={iconSize}
                        />
                        {t('Receipt')}
                    </button>
                    <button
                        className={styles.btn}
                        onClick={() => editInvoice(invoiceData._id)}
                    >
                        <BorderColorIcon style={iconSize}
                        />
                        {t('Edit_Invoice')}
                    </button>

                    <button
                        // disabled={status === 'Paid' ? true : false}
                        className={styles.btn}
                        onClick={() => setOpen((prev) => !prev)}>
                        <MonetizationOnIcon style={iconSize}
                        />
                        {t('Record_Payment')}
                    </button>
                </div>
            )
            }

            {invoice?.paymentRecords?.length !== 0 && (
                <PaymentHistory paymentRecords={invoiceData?.paymentRecords}/>
            )}

            <Modal open={open} setOpen={setOpen} invoice={invoice}/>
            <div className={'invoicePage ' + styles.invoiceLayout}>
                <Container className={'invoiceContainer '} style={{maxWidth: 'unset'}}>

                    <Grid container justifyContent="space-between" style={{padding: '5x 0px'}}>
                        { /* {!invoice?.creator?.includes(user?.result._id || user?.result?.googleId) ?
                            (
                                <Grid item>
                                </Grid>
                            )
                            : (
                                <Grid item onClick={() => history.push('/settings')} style={{cursor: 'pointer'}}>
                                    {logoPath ? <img src={logoPath} alt="Logo" className={styles.logo}/> : ""}
                                    <h2>{company?.businessName}</h2>
                                    <h4>Tel/{company?.phoneNumber}</h4>
                                    <h5>{company?.contactAddress}</h5>

                                </Grid>

                            )} */}
                        {/*<!-- Sorry I have to use table here to support print in server side Mohamed ALi  -->*/}
                        <table style={{width: '100%'}}>
                            <thead></thead>
                            <tbody>
                            <tr>
                                <td width='25%'>
                                    <Grid item onClick={() => history.push('/settings')} style={{cursor: 'pointer'}}>
                                        {logoPath ? <img src={logoPath} alt="Logo" className={styles.logo}/> : ""}
                                        {((profileData?.businessName) && <h3>{profileData?.businessName}</h3>)}
                                        {(profileData?.phoneNumber) && (<h4>{profileData?.phoneNumber}</h4>)}

                                    </Grid>
                                </td>
                                <td width='25%'>
                                    <Grid item>
                                        <Typography variant="overline" style={{color: 'gray'}}
                                                    gutterBottom>{t('Date')}</Typography>
                                        <Typography variant="body2"
                                                    gutterBottom>
                                            {moment(invoiceData?.createdAt).format("DD/MM/YYYY")}
                                        </Typography>
                                        <Typography variant="overline" style={{color: 'gray'}}
                                                    gutterBottom>{t('Due_Date')}</Typography>
                                        <Typography variant="body2"
                                                    gutterBottom>{selectedDate ? moment(selectedDate).format("DD/MM/YYYY") : '27/12/2021'}</Typography>
                                        <Typography variant="overline" style={{color: 'gray'}}
                                                    gutterBottom>{t('COMMERCIAL_RECORD')}</Typography>
                                        {(profileData?.commercialRecord) && (<Typography variant="body2"
                                                                                         gutterBottom>{profileData?.commercialRecord}</Typography>)}
                                    </Grid>
                                </td>
                                <td width='25%'>
                                    <Grid item>
                                        <Typography variant="overline" style={{color: 'gray'}}
                                                    gutterBottom>{t('Status')}</Typography>
                                        <Typography variant="body2" gutterBottom
                                                    style={{color: checkStatus()}}>{t(totalAmountReceived >= total ? 'Paid' : status)}</Typography>

                                        <Typography variant="overline" gutterBottom>{t('Amount')}</Typography>
                                        <Typography variant="body2"
                                                    gutterBottom>{currency} {toCommas(total)}</Typography>
                                        <Typography variant="overline" style={{color: 'gray'}}
                                                    gutterBottom>{t('VAT_RECORD')}</Typography>
                                        {(profileData?.vatRecord) && (<Typography variant="body2"
                                                                                  gutterBottom>{profileData?.vatRecord}</Typography>)}
                                    </Grid>
                                </td>
                                <td width='25%'>
                                    <Grid item style={{marginRight: 40, textAlign: 'right'}}>
                                        <Typography style={{
                                            lineSpacing: 1,
                                            fontSize: 25,
                                            fontWeight: 700,
                                            color: 'gray'
                                        }}>{t(Number(total - totalAmountReceived) <= 0 ? 'Receipt' : type)}</Typography>
                                        <Typography variant="overline" style={{
                                            fontSize: 20,
                                            color: 'red'
                                        }}>No: {invoiceData?.invoiceNumber}</Typography>
                                        <h6>{profileData?.contactAddress}</h6>
                                    </Grid>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Grid>
                </Container>

                <Divider/>
                <div
                    className={styles.billedTo}> {t('Bill_to')}: &nbsp; {client?.name},{client?.email},phone:{client?.phone},{client?.address}</div>

                <form id="invoiceForm">
                    <div id="invoiceContiner">

                        <TableContainer component={Paper}>
                            <Table className={(i18n.resolvedLanguage === 'ar') ? ' rtl ' : ' ' + classes.table}
                                   aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('Item')}</TableCell>
                                        <TableCell>{t('Qty')}</TableCell>
                                        <TableCell>{t('Price')}</TableCell>
                                        <TableCell>{t('Disc')}(%)</TableCell>
                                        <TableCell>{t('Amount')}</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoiceData?.items?.map((itemField, index) => (
                                        <TableRow key={index}>
                                            <TableCell scope="row"
                                                       style={{width: '40%'}}> {itemField.itemName} </TableCell>
                                            <TableCell align="right">{itemField?.quantity}  </TableCell>
                                            <TableCell align="right"> {itemField?.unitPrice} </TableCell>
                                            <TableCell align="right"> {itemField?.discount}</TableCell>
                                            <TableCell
                                                align="right"> {toFixedFloat((itemField?.quantity * itemField.unitPrice) - (itemField.quantity * itemField.unitPrice) * itemField.discount / 100)} </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={styles.addButton}>
                        </div>
                    </div>
                    <div>
                        <table
                            className={((i18n.resolvedLanguage === 'ar') ? ' rtl ' : ' ') + styles.smallTableFont + ' ' + classes.table}>
                            <tbody>
                            <tr>
                                <td>{t('Subtotal')}:</td>
                                <td>{subTotal}</td>
                                <td rowSpan="7">
                                    {t('Notes/Terms')}:
                                    {invoiceData.notes}
                                </td>
                                <td rowSpan="7">
                                    <QRCode
                                        value={window.location.href}  renderAs="svg"  style={{display: 'inline', margin: '10px'}}/>
                                    {(saudiQrCode) &&
                                        <QRCode value={saudiQrCode} fgColor="#13388c"  renderAs="svg" style={{display: 'inline', margin: '10px'}}/>}
                                </td>
                            </tr>
                            <tr>
                                <td>{`VAT(${rates}%):`}</td>
                                <td>{vat}</td>
                            </tr>
                            {(invoiceData.discount > 0 ) && <tr>
                                <td>{t('Discount')}</td>
                                <td>{currency} {toCommas(toFixedFloat( invoiceData.discount))}</td>
                            </tr>}
                            <tr>
                                <td>{t('Total')}</td>
                                <td>{currency} {toCommas(total)}</td>
                            </tr>
                            <tr>
                                <td>{t('Paid')}</td>
                                <td>{currency} {toCommas(totalAmountReceived)}</td>
                            </tr>

                            <tr>
                                <td>{t('Balance')}</td>
                                <td>{currency} {toCommas(toFixedFloat(total - totalAmountReceived ))}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>


                    {/* <button className={styles.submitButton} type="submit">Save and continue</button> */}
                </form>
            </div>
        </div>

    )
}

export default InvoiceDetails
