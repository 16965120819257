import * as api from '../api/index'
import {initialState} from '../initialState'
import {
    ADD_NEW,
    UPDATE,
    DELETE,
    GET_INVOICE,
    FETCH_INVOICE_BY_USER,
    START_LOADING,
    END_LOADING,
    FETCH_INVOICE_NUMBER,
    GET_ADD_NEW_INVOICE,
    ADD_NEW_RECEIPT
} from './constants'
import {fetchInvoiceNumberByUser} from "../api/index";

// export const getInvoices = () => async (dispatch)=> {
//     try {
//         const { data } = await api.fetchInvoices()
//         dispatch({ type: FETCH_ALL, payload: data })
//     } catch (error) {
//         console.log(error)
//     }
// }

export const getInvoicesByUser = (searchQuery,page) => async (dispatch) => {
    try {
        dispatch({type: START_LOADING})
        const {data: {data, numberOfPages}} = await api.fetchInvoicesByUser(searchQuery,page)
        console.log("from serve rnumebr of pages : " + numberOfPages)
        dispatch({type: FETCH_INVOICE_BY_USER, payload: {invoices: data, numberOfPages: numberOfPages}});
        dispatch({type: END_LOADING})
    } catch (error) {
        console.log("+++++++++++++++++++++++++++++++++-----------------------")
        console.log(error)
        console.log(error.response)
        console.log("+++++++++++++++++++++++++++++++++-----------------------")

    }
}


export const getAddNewInvoice = () => async (dispatch) => {
    const user = JSON.parse(localStorage.getItem('profile'))
    try {
        const data = {
            ...initialState, items: [
                {itemName: '', unitPrice: '', quantity: '', discount: ''},
            ]
        }
        console.log("=======================================")
        console.log(data)
        console.log("=======================================")
        const businessDetails = await api.fetchProfilesByUser({search: user?.result?._id || user?.result?.googleId})
        const invoiceData = {...data, businessDetails}
        dispatch({type: GET_ADD_NEW_INVOICE, payload: invoiceData})
    } catch (error) {
        console.log(error)
    }
}

export const getInvoice = (id) => async (dispatch) => {

    const user = JSON.parse(localStorage.getItem('profile'))

    try {
        const {data} = await api.fetchInvoice(id)
        const businessDetails = await api.fetchProfilesByUser({search: user?.result?._id || user?.result?.googleId})
        const invoiceData = {...data, businessDetails}
        console.log(invoiceData)
        dispatch({type: GET_INVOICE, payload: invoiceData})
    } catch (error) {
        console.log(error)
    }
}

export const createReceipt = (invoice) => async (dispatch) => {
    try {
        dispatch({type: START_LOADING})
        const {data} = await api.addInvoice(invoice)
        dispatch({type: ADD_NEW_RECEIPT, payload: data})
        window.print();
        dispatch({type: END_LOADING})
    } catch (error) {
        console.log(error);
        window.print();
    }
}

export const createInvoice = (invoice, history) => async (dispatch) => {
    try {
        dispatch({type: START_LOADING})
        const {data} = await api.addInvoice(invoice)
        dispatch({type: ADD_NEW, payload: data})
        history.push(`/invoice/${data._id}`)
        dispatch({type: END_LOADING})
    } catch (error) {
        console.log(error)
    }
}

export const updateInvoice = (id, invoice) => async (dispatch) => {

    try {
        const {data} = await api.updateInvoice(id, invoice)
        dispatch({type: UPDATE, payload: data})

    } catch (error) {
        console.log(error)
    }
}

export const deleteInvoice = (id, openSnackbar) => async (dispatch) => {
    try {
        await api.deleteInvoice(id)

        dispatch({type: DELETE, payload: id})
        openSnackbar("Invoice deleted successfully")
    } catch (error) {
        console.log(error.response)
    }
}

export const getLastInvoiceNumberByUser = (searchQuery) => async (dispatch) => {
    try {
        dispatch({type: START_LOADING})
        const data = await api.fetchInvoiceNumberByUser(searchQuery)
        dispatch({type: FETCH_INVOICE_NUMBER, payload: data});
        dispatch({type: END_LOADING})
        return data;
    } catch (error) {
        console.log(error.response)

    }
}
