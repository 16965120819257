import {
    START_LOADING,
    END_LOADING,
    CREATE_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY,
    FETCH_CATEGORY,
    FETCH_CATEGORIES_BY_USER
} from '../actions/constants';

const categories = (state = {isLoading: true, categories: []}, action) => {
    switch (action.type) {
        case START_LOADING:
            return {...state, isLoading: true};
        case END_LOADING:
            return {...state, isLoading: false};
        case FETCH_CATEGORIES_BY_USER:
            return {...state, categories: action.payload.categories, numberOfPages: action.payload.numberOfPages};
        case FETCH_CATEGORY:
            return {...state, category: action.payload.category};
        case CREATE_CATEGORY:
            return {...state, categories: [...state.categories, action.payload]};
        case UPDATE_CATEGORY:
            return {
                ...state,
                categories: state.categories.map((category) => (category._id === action.payload._id ? action.payload : category))
            };
        case DELETE_CATEGORY:
            return {...state, categories: state.categories.filter((client) => client._id !== action.payload)};
        default:
            return state;
    }
};

export default categories

