import React from 'react'
import styles from './Home.module.css'
import {useTranslation, Trans} from 'react-i18next';
import Link from "react-router-dom/es/Link";
import Subscription from '../paypal/Subscription'
import {featuresar} from "../../utils/featchersar";
import {features} from "../../utils/featchers";
import {Container, ImageList, ImageListItem, Paper, TableCell} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import {makeStyles} from "@material-ui/core/styles";
const useStyles = makeStyles({
    table: {
        minWidth: 250,
    },
});
const Home = () => {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const itemData = [
        {img:"/images/pos-front.png",cols:"",title:""},
        {img:"/images/invoice.png",cols:"",title:""},
        {img:"/images/pos-payment.png",cols:"",title:""},
        {img:"/images/pos-print.png",cols:"",title:""},
    ];
    return (
        <Container style={{backgroundColor:'#ffffff'}}>

            <section className={styles.hero} style={{textAlign: "center"}}>
                <h2>  {t('Quantum_Billing_is_Easiest_invoicing_for_small_businesses')}</h2>
                <div className={styles.paragraph}>
                    <p>{t('Unlimited_invoicing,_unlimited_receipts,_Unlimited_quotations,_no_adds,_no_hidden_fees')} </p>
                </div>

            </section>

                {itemData.map((item) => (
                        <img src={item.img} alt={item.title} className={["img-responsive", styles.screenImages].join(' ')} style={{marginBottom:'30px'}}/>
                ))}

            <section className={styles.hero} style={{textAlign: "center"}}>
                <h2>  {t('Quantum_Billing_is_Easiest_invoicing_for_small_businesses')}</h2>
                <div className={styles.paragraph}>
                    <p>{t('Unlimited_invoicing,_unlimited_receipts,_Unlimited_quotations,_no_adds,_no_hidden_fees')} </p>
                </div>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                            {((i18n.resolvedLanguage === 'ar') ? featuresar : features).map((data) => (
                                <TableRow key={data[0]}>
                                    <TableCell >{data[0]}</TableCell>
                                    <TableCell align="right">{data[1]}</TableCell>
                                    <TableCell align="right">{data[2]}</TableCell>
                                    <TableCell align="right">{data[3]}</TableCell>
                                    <TableCell align="right">{data[4]}</TableCell>
                                </TableRow>

                            ))}
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Free</TableCell>
                                <TableCell style={{padding: '10px'}}>
                                    <Subscription planId='P-1Y1444755P1762824MHEMJJI'/></TableCell>
                                <TableCell><Subscription planId='P-34974314Y7631323PMHEO6NQ'/></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={styles.imgContainer}>

                    <Link to="/dashboard">
                        <img src="/firstinvoice.png" alt="invoicing-app" className="img-responsive"/>
                    </Link>
                </div>
            </section>
        </Container>
    )
}

export default Home
