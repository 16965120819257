import {useTranslation} from "react-i18next";

export const FETCH_ALL = "FETCH_ALL"
export const ADD_NEW = "ADD_NEW"
export const UPDATE = "UPDATE"
export const DELETE = "DELETE"
export const GET_INVOICE = "GET_INVOICE"
export const GET_ADD_NEW_INVOICE = "GET_ADD_NEW_INVOICE"
export const FETCH_INVOICE_BY_USER = "FETCH_INVOICE_BY_USER"


export const ALL_CLIENTS = "ALL_CLIENTS"
export const UPDATE_CLIENT = "UPDATE_CLIENT"
export const DELETE_CLIENT = "DELETE_CLIENT"
export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT"
export const FETCH_CLIENTS_BY_USER = 'FETCH_CLIENTS_BY_USER';

export const AUTH = "AUTH"
export const LOGOUT = "LOGOUT"
export const UPDATE_USER = "UPDATE_USER"
export const FETCH_USERS = "FETCH_USERS"
export const START_LOADING = "START_LOADING"
export const END_LOADING = "END_LOADING"
export const FETCH_CLIENT = "FETCH_CLIENT"

export const CREATE_PROFILE = 'CREATE_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const FETCH_PROFILES = 'FETCH_PROFILES';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_BY_USER = 'FETCH_PROFILE_USER';
export const FETCH_PROFILES_BY_USER = 'FETCH_PROFILES_BY_USER';

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const FETCH_FILE = 'FETCH_FILE';
export const FETCH_FILES_BY_USER = 'FETCH_FILES_BY_USER';
export const FETCH_INVOICE_NUMBER = 'FETCH_INVOICE_NUMBER';

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const FETCH_CATEGORIES_BY_USER = 'FETCH_CATEGORIES_BY_USER';

export const CREATE_ITEM = 'CREATE_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const FETCH_ITEM = 'FETCH_ITEM';
export const FETCH_ITEMS_BY_USER = 'FETCH_ITEMS_BY_USER';
export const ITEMS_LAST_MODIFIED_BY_USER = 'ITEMS_LAST_MODIFIED_BY_USER';
export const FIND_ITEM_BY_BARCODE = 'FIND_ITEM_BY_BARCODE';
export const ITEM_CATEGORY = 'ITEM_CATEGORY';
export const ADD_NEW_RECEIPT = 'ADD_NEW_RECEIPT';
export  const paymentMethods = [
    {title: 'Credit',paidAmount:0,note:'',date:new Date().toLocaleString()},
    {title: 'Cash',paidAmount:0,note:'',date:new Date().toLocaleString()},
    {title: 'Transfer',paidAmount:0,note:'',date:new Date().toLocaleString()},
    {title: 'Check',paidAmount:0,note:'',date:new Date().toLocaleString()},
    {title: 'PayPal',paidAmount:0,note:'',date:new Date().toLocaleString()},
    {title: 'Others',paidAmount:0,note:'',date:new Date().toLocaleString()}
]

