import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import {useDispatch, useSelector} from 'react-redux'
import {createItem, updateItem} from '../../actions/itemActions'
import {useSnackbar} from 'react-simple-snackbar'
import {useTranslation, Trans} from 'react-i18next';
import css from './Items.module.css'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Uploader from "../Settings/Form/Uploader";
import {
    Avatar,
    Switch,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {fetchFile} from "../../actions/upload";
import {getCategoriesByUser} from "../../actions/categoryActions";


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: '#1976D2',
        marginLeft: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'white',
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const AddItem = ({setOpen, open, currentId, setCurrentId}) => {
    const location = useLocation()
    const [itemData, setItemData] = useState({
        shortName: '',
        description: '',
        image: '',
        lastModified: new Date().toISOString(),
        expireDate: '',
        sellingPrice: '',
        buyingPrice: '',
        itemsCount: '',
        barcode: '',
        category: '',
        creator: '',
        enableTax: true,
        priceIncludesTax: false
    })
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
    const dispatch = useDispatch()
    const selectedItem = useSelector((state) => currentId ? state.items.items.find((c) => c._id === currentId) : null)
    // eslint-disable-next-line 
    const [openSnackbar, closeSnackbar] = useSnackbar()
    const {t, i18n} = useTranslation();
    const [logoPath, setLogoPath] = useState();
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const {categories} = useSelector((state) => state.categories)


    useEffect(() => {
        dispatch(getCategoriesByUser({search: user?.result._id || user?.result?.googleId}, 0));
    }, [location, dispatch])

    useEffect(() => {
        if (selectedItem) {
            setItemData(selectedItem)
        } else {
            clear()
        }
    }, [currentId, selectedItem])

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile')))
    }, [location])


    useEffect(() => {
        let checkId = user?.result?._id
        if (checkId !== undefined) {
            setItemData({...itemData, creator: checkId})
        } else {
            setItemData({...itemData, creator: user?.result?.googleId})
        }
        console.log(itemData)
    }, [location])

    useEffect(async () => {
        if (itemData?.image) {
            const res = await dispatch(fetchFile(itemData.image));
            setLogoPath(res);
        }
    }, [itemData.image])

    const handleSubmitItem = (e) => {
        e.preventDefault()

        if (!itemData.shortName) {
            console.log(itemData)
            return;
        }
        console.log(itemData)
        let checkId = user?.result?._id
        if (checkId !== undefined) {
            setItemData({...itemData, creator: checkId})
        } else {
            setItemData({...itemData, creator: user?.result?.googleId})
        }

        if (currentId) {
            dispatch(updateItem(currentId, itemData, openSnackbar))
        } else {
            dispatch(createItem(itemData, openSnackbar))
        }

        clear()
        //setOpen(false);
    }

    const clear = () => {
        //setCurrentId(null)
        let checkId = user?.result?._id
        setItemData({
            shortName: '',
            description: '',
            image: '',
            lastModified: new Date().toISOString(),
            expireDate: '',
            sellingPrice: '',
            buyingPrice: '',
            itemsCount: '',
            barcode: '',
            category: '',
            enableTax: true, creator: checkId || user?.result?.googleId
        })
        setLogoPath('');
    }

    const handleClose = () => {
        setOpen(false);
    };

    const inputStyle = {
        display: "block",
        padding: "10px",
        width: "100%",
        height: "45px",
        lineHeight: 1.25,
        color: "#55595c",
        backgroundColor: "#fff",
        backgroundImage: "none",
        backgroundClip: "padding-box",
        borderTop: "0",
        borderRight: "0",
        borderBottom: "1px solid #eee",
        borderLeft: "0",
        direction: (i18n.resolvedLanguage === 'ar') ? ' rtl ' : ' ',
        borderRadius: "3px",
        transition: "all 0.25s cubic-bezier(0.4, 0, 1, 1)"
    }
    const boxClasses = `customInputs ${css.customInputs}  ${((i18n.resolvedLanguage === 'ar') ? css.floatRTL : css.floatLTR)}`
    const handleChange = (e) => {
        (e.target.type === 'checkbox') ?
            setItemData({...itemData, [e.target.name]: e.target.checked}) :
            setItemData({...itemData, [e.target.name]: e.target.value});

    }


    const handleDateChange = (date) => {
        setSelectedDate(date);
        setItemData({...itemData, expireDate: date})
    };
    const setUploadedFile = (file) => setItemData({...itemData, image: file});
    return (
        <div>
            <form autoComplete="off">
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title"  style={{direction:i18n.resolvedLanguage === 'ar'? 'rtl' : 'ltr'}} open={open} fullWidth>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}
                                 style={{paddingLeft: '20px', color: 'white'}}>
                        {currentId ? 'Edit Item ' : 'Add new Item'}
                    </DialogTitle>
                    <DialogContent dividers  >


                        <div className={boxClasses}>
                            <TextField id="shortName" name='shortName' label={t('Name')} type="text"
                                       onChange={handleChange} autoComplete="off"
                                       value={itemData.shortName} variant="outlined" style={{margin: '10px'}}/>
                        </div>
                        <div className={boxClasses} style={{height:'90px'}}>
                            <Avatar style={{width: '80px', height: '80px', marginLeft: 'auto',marginRight: 'auto'}} src={logoPath} alt="" />
                        </div>
                        <div className={boxClasses}>
                            <TextField id="description" name='description' label={t('Description')} type="text"
                                       onChange={handleChange} autoComplete="off"
                                       value={itemData.description} variant="outlined" style={{margin: '10px'}}/>

                        </div>

                        <div className={boxClasses}>
                            <Uploader setUploadedFile={setUploadedFile}
                                      cstyle={{margin: '0 !important', border: '0 !important'}}/>
                            <input
                                placeholder={t('Image')}
                                style={inputStyle}
                                name='image'
                                type='hidden'
                                autoComplete="off"
                                onChange={handleChange}
                                value={itemData.image}
                            />

                        </div>
                        <div className={boxClasses}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="expireDate"
                                    name="expireDate"
                                    label="expireDate"
                                    value={itemData?.expireDate || selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>

                        </div>
                        <div className={boxClasses}>
                            <TextField id="sellingPrice" name='sellingPrice' label={t('Selling Price')} type="text"
                                       onChange={handleChange} autoComplete="off"
                                       value={itemData.sellingPrice} variant="outlined" style={{margin: '10px'}}/>
                        </div>
                        <div className={boxClasses}>
                            <TextField id="buyingPrice" name='buyingPrice' label={t('Buying Price')} type="text"
                                       onChange={handleChange} autoComplete="off"
                                       value={itemData.buyingPrice} variant="outlined" style={{margin: '10px'}}/>
                        </div>
                        <div className={boxClasses}>
                            <TextField id="itemsCount" name='itemsCount' label={t('Items Count')} type="text"
                                       onChange={handleChange} autoComplete="off"
                                       value={itemData.itemsCount} variant="outlined" style={{margin: '10px'}}/>
                        </div>
                        <div className={boxClasses}>

                            <TextField id="barcode" name='barcode' label={t('Barcode')} type="text"
                                       onChange={handleChange} autoComplete="off"
                                       value={itemData.barcode} variant="outlined" style={{margin: '10px'}}/>
                        </div>
                        <div className={boxClasses}>
                            <FormControl variant="outlined" style={{width: '90%', margin: '10px'}}>
                                <InputLabel id="category">Select Category</InputLabel>
                                <Select
                                    labelId="category"
                                    id="category"
                                    name='category'
                                    value={itemData.category}
                                    onChange={handleChange}
                                    label={t('Select Category')}

                                >
                                    <MenuItem value="">
                                        <em>None </em>
                                    </MenuItem>
                                    {categories.map(cat => (
                                        <MenuItem value={cat._id}>{cat.category}</MenuItem>
                                    ))}
                                </Select>

                            </FormControl>
                        </div>
                        <FormControlLabel
                            control={<Switch checked={itemData.enableTax} onChange={handleChange}
                                             name="enableTax"/>}
                            label={t('Enable_Tax')}
                        />
                        <FormControlLabel
                            control={<Switch checked={itemData.priceIncludesTax} onChange={handleChange}
                                             name="priceIncludesTax"/>}
                            label={t('Price_Includes_Tax')}
                        />


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSubmitItem} variant="contained" style={{marginRight: '25px'}}>
                            {t('Save')}
                        </Button>
                    </DialogActions>
                </Dialog>

            </form>
        </div>
    );
}

export default AddItem